<template>
  <div class="container">
    <div class="history-filter">
      <al-nav>
        <template slot="left">
          <span class="label">起止时间 </span>
          <el-date-picker
            prefix-icon="no"
            v-model="dateRange"
            type="daterange"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            range-separator="-"
            class="datetime-input"
            @change="handleSearch"
          >
          </el-date-picker>
        </template>
        <template slot="right">
          <el-input
            placeholder="请输入主题/参与人/猪耳号"
            v-model="keyword"
            class="text-input"
          >
          <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
          </el-input>

         
        </template>
      </al-nav>
    </div>

    <div class="card-list">
      <record-card
        v-for="item in list"
        :key="item.id"
        :data="item"
        mode="history"
        @export="handleExport(item)"
        @view="handleView(item)"
      />
    </div>
    <div class="pagination">
      <el-pagination
        :pager-count="11"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="选中猪只"
      :visible.sync="listVisible"
      custom-class="pig-list-dialog"
    >
      <ul class="pig-list">
        <li class="pig-item" v-for="item in activePigIds" :key="item">
          <span class="pig-icon"></span>
          <span class="pig-id">{{ item }}</span>
          <div class="pig-btn" @click="handleShowPig(item)">详情</div>
          <el-popconfirm
            title="您确定删除选中猪只吗？"
            @confirm="handleDeletePig(item)"
            className="aaa"
          >
            <div class="pig-btn delete" slot="reference">删除</div>
          </el-popconfirm>
        </li>
      </ul>
    </el-dialog>

    <el-dialog custom-class="pig-detail-modal" :visible.sync="dialogVisible">
      <i
        class="el-icon-circle-close close-icon"
        @click="dialogVisible = false"
      ></i>
      <pig-card :data="activePig" />
    </el-dialog>
  </div>
</template>

<script>
import { getListFinished, getPigInfo, deletePig, pigExport } from '../../api'
import { exportFile } from '../../utils/request'
import PigCard from '../../components/PigCard.vue'
import AlNav from '../../components/Nav.vue'
import RecordCard from '../../ui-components/RecordCard.vue'

export default {
  components: {
    PigCard,
    AlNav,
    RecordCard,
  },
  data() {
    return {
      dateRange: [],
      keyword: '',
      pigNo: '',
      list: [],
      total: 0,
      pageNum: 1,
      pageSize: 8,
      dialogVisible: false,
      activePigIds: [],
      activePig: {},
      activeRoomId: '',
      listVisible: false,
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      const query = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        keyWord: this.keyword,
        pigId: this.pigNo,
      }
      if (this.dateRange != null && this.dateRange.length) {
        query.startTime = this.dateRange[0].valueOf()
        query.endTime = this.dateRange[1].valueOf()
      }
      const res = await getListFinished(query)
      this.list = res.data.list
      this.total = res.data.total
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    handleExport(item) {
      // exportFile(`/acp/api/videoRecord/export/${item.id}`)
      let data = {
        id: item.id,
      }
      pigExport(data).then((res) => {
        // console.log(res)
      })
    },
    handleSearch() {
      this.pageNum = 1
      this.getList()
    },
    handleView(data) {
      if (data.pigId) {
        this.activeRoomId = data.roomId
        this.activePigIds = data.pigId.split(',')
        this.listVisible = true
      }
    },
    async handleShowPig(pigId) {
      const res = await getPigInfo({
        pigId: pigId,
        roomId: this.activeRoomId,
      })
      if (res.success) {
        this.activePig = res.data || {}
        this.dialogVisible = true
      }
    },
    async handleDeletePig(pigId) {
      const res = await deletePig({
        pigId: pigId,
        roomId: this.activeRoomId,
      })
      if (res.success) {
        this.activePigIds = this.activePigIds.filter((i) => i !== pigId)
        this.getList()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.close-icon {
  font-size: 22px;
  color: var(--al-text-color2);
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}
.container {
  padding: 0 20px;
  .history-filter {
    .label {
      font-size: 14px;
      color: var(--al-text-color1);
      margin-right: 13px;
    }
  }
  .card-list {
    display: flex;
    flex-flow: row wrap;
    height: 540px;
    // overflow: auto;
  }
  .pagination {
    display: flex;
    justify-content: center;
  }
}

.pig-list {
  overflow: auto;
  height: 160px;
}
.pig-list::-webkit-scrollbar {
  // display: none;
}
.pig-item {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: var(--al-text-color1);
  border-bottom: 1px solid var(--al-border-color);
  .pig-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('../..//assets/img/pig-white-icon.png') no-repeat center
      center/contain;
    user-select: none;
  }
  .pig-id {
    flex-grow: 2;
    margin-left: 7px;
  }
  .pig-btn {
    height: 20px;
    width: 53px;
    background: var(--al-main-03-color);
    border-radius: 10px;
    margin-left: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.delete {
      background: rgba(255, 54, 92, 0.3);
    }
  }
}
/deep/ .el-input__inner {
  height: 100%;
  background: transparent;
  // border: none;
  border: 1px solid var(--al-border-color);
  color: var(--al-text-color1);
}
/deep/.el-range-input {
  background: transparent;
  color: var(--al-text-color1);
}
/deep/.el-range-separator {
  color: var(--al-text-color1);
}
/deep/.el-range-input::-webkit-input-placeholder {
  color: var(--al-text-color1);
}
/deep/.el-input__icon {
  line-height: 100%;
  color: var(--al-text-color1);
}
/deep/.is-active:hover {
  border: 1px solid var(--al-border-color);
}
</style>

<style lang="scss">
.el-popper{
   background: #1c213e;
    border-color: var(--al-border-color);
}
.el-popconfirm__main{
  color: var(--al-text-color1);
}
.el-picker-panel {
  background: #1c213e;
  border-color: var(--al-border-color);
}
.el-popper[x-placement^='bottom'] .popper__arrow::after {
  border-bottom-color: #1c213e;
}
.el-popper[x-placement^='bottom'] .popper__arrow {
  border-bottom-color: var(--al-border-color);
}
.el-date-range-picker__content.is-left {
  border-color: var(--al-border-color);
}
.el-picker-panel__footer {
  background: #1c213e;
}
.el-date-range-picker__header {
  color: var(--al-main-color);
}
.el-picker-panel__icon-btn {
  color: var(--al-main-color);
}
.el-date-table th {
  color: var(--al-main-color);
  border-color: var(--al-border-color);
}
.available div {
  background: #222d4e;
  color: #747ba9;
}
.el-date-table td.next-month,
.el-date-table td.prev-month {
  color: #747ba9;
  opacity: 0.3;
}
.el-date-table td.start-date span {
  background-color: var(--al-main-color);
}

.el-date-table td.in-range div,
.el-date-table td.in-range div:hover,
.el-date-table.is-week-mode .el-date-table__row.current div,
.el-date-table.is-week-mode .el-date-table__row:hover div {
  background: rgba(41, 56, 105, 1);
  color: var(--al-main-color);
}
.el-date-table td.end-date span,
.el-date-table td.start-date span {
  color: #fff !important;
}
.datetime-input {
  height: 30px !important;
  width: 390px !important;
  border: 1px solid var(--al-border-color);
  border-radius: 5px;
  .el-input__inner {
    height: 100%;
    background: transparent;
    border: none;
    color: var(--al-text-color1);
  }
  .el-input__inner::-webkit-input-placeholder {
    color: var(--al-text-color1);
  }
  .el-input__icon {
    line-height: 100%;
    color: var(--al-text-color1);
  }
}
.text-input {
  margin-left: 11px;
  width: 260px;
  height: 30px;
  border: 1px solid var(--al-border-color);
  border-radius: 5px;
  .el-input__inner {
    height: 100%;
    background: transparent;
    border: none;
    color: var(--al-text-color1);
  }
  .el-input__inner::-webkit-input-placeholder {
    color: var(--al-text-color1);
  }
  .el-input__icon {
    line-height: 100%;
    color: var(--al-text-color1);
  }
}
.pagination {
  .el-pagination {
    color: var(--al-text-color1);
  }
  .el-pagination button {
    background: transparent;
  }
  .el-pager li {
    background: transparent;
  }
  .el-pager li.active {
    color: var(--al-main-color);
  }
  .btn-prev,
  .btn-next {
    color: var(--al-text-color1);
  }
  .el-pager li.btn-quicknext,
  .el-pager li.btn-quickprev {
    color: var(--al-text-color1);
  }
}

.pig-list-dialog {
  width: 417px;
  height: 248px;
  background: var(--al-dialog-bg-color);
  border: 1px solid var(--al-border-color);
  border-radius: 3px;
  .el-dialog__header {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-bottom: 1px solid var(--al-border-color);
  }
  .el-dialog__title {
    font-size: 18px;
    color: var(--al-text-color1);
  }
  .el-dialog__body {
    padding: 13px 20px;
  }

  .el-dialog__footer {
    padding: 0;
  }
}

.pig-detail-modal {
  width: 800px;
  height: 473px;
  background: var(--al-dialog-bg-color);
  border: 1px solid var(--al-border-color);
  border-radius: 5px;
  .el-dialog__body {
    padding: 71px 62px 0px 62px;
  }
  .el-dialog__header {
    display: none;
  }
}
.el-date-editor .el-range-separator {
  line-height: 22px;
}
.history-filter{
  .el-input-group__append{
    background: transparent;
    border: none;
    color: var(--al-text-color1);
  }
}
</style>
