<template>
  <div class="card-box">
    <div class="card-header">
      <i><svg-icon icon-class="card_pig" class="svg"></svg-icon></i>
      <span>主题：{{ data.roomName }}</span>
    </div>
    <div class="card-main">
      <p>
        选猪ID:<span>{{ data.roomId }}</span>
      </p>
      <p>
        参与人:<span>{{ data.username }}</span>
      </p>
      <p>
        开始时间:
        <span>{{
          data.startTime
            ? $moment(data.startTime).format('YYYY-MM-DD HH:mm')
            : ''
        }}</span>
      </p>
      <p v-if="mode === 'history'">
        结束时间:
        <span>{{
          data.endTime ? $moment(data.endTime).format('YYYY-MM-DD HH:mm') : ''
        }}</span>
      </p>
    </div>
    <div class="card-footer">
      <template v-if="mode === 'home'">
        <div class="btn" @click="$emit('join')">加入</div>
      </template>
      <template v-else>
        <div class="btn" @click="$emit('view')">
          已选({{ data.pigId ? data.pigId.split(',').length : 0 }})
        </div>
        <div class="btn" @click="$emit('export')">导出</div>
      </template>
    </div>
  </div>
</template>

<script>
import svgIcon from '../components/svgIcon.vue'
export default {
  props: ['data', 'mode'],
  components: {
    svgIcon,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.card-box {
  margin-right: 17px;
  margin-bottom: 20px;
  width: 294px;
  height: 250px;
  background: var(--al-component-bg-03-color);
  border: 1px solid var(--al-border-color);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  &:nth-child(4n + 4) {
    margin-right: 0;
  }
  .card-header {
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 0 19px;
    width: 295px;
    height: 53px;
    // background: var(--al-title-bg-03-color);
    border-radius: 3px 3px 0px 0px;
    i {
      width: 28px;
      height: 19px;
      display: inline-block;
      margin-right: 10px;
      .svg {
        color: var(--al-secondary-color);
      }
    }
    span {
      width: calc(100% - 28px - 10px - 19px);
      font-size: 15px;
      color: var(--al-secondary-color);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .card-main {
    flex-grow: 2;
    padding-top: 25px;
    padding-left: 19px;
    font-size: 12px;
    color: var(--al-text-05-color1);
    p {
      margin-bottom: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      span {
        color: var(--al-text-color1);
        margin-left: 12px;
      }
    }
  }
  .card-footer {
    height: 47px;
    display: flex;
    justify-content: center;
    .btn {
      height: 27px;
      width: 67px;
      background: var(--al-main-color);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      // color: var(--al-text-color2);
      color: #fff;
      margin: 0 18px;
      cursor: pointer;
    }
  }
}
.card-box:hover {
  box-shadow: 0px 2px 20px 0 rgba(0, 200, 255, 0.12),
    0 0 20px 0 rgba(0, 200, 255, 0.4);
  .btn {
    background: var(--al-main-02-color);
  }
}
</style>
