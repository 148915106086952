<template>
  <div class="wrap">
    <nav>
      <el-input
        v-model="linkName"
        placeholder="请输入姓名"
        clearable
        @input="getContactList()"
        size="mini"
      ></el-input>
    </nav>
    <ul class="list">
      <li v-for="(item, index) in list" :key="index">
        <div class="left">
          <div class="avatar">
            <!-- <img :src="avatar" alt="" /> -->
            <svg-icon icon-class="userAvatar" class="svg"></svg-icon>
          </div>

          <span>{{ item.username }}</span>
          <div class="eqit-group">
            <div
              class="eqit"
              v-if="item.type == 1 || item.type == 5 || item.type == 6"
            >
              <img :src="pc" alt="" />
            </div>
            <div
              class="eqit"
              v-if="
                item.type == 2 ||
                item.type == 4 ||
                item.type == 5 ||
                item.type == 6
              "
            >
              <img :src="android" alt="" class="eqit" />
            </div>
            <div
              class="eqit"
              v-if="item.type == 3 || item.type == 4 || item.type == 6"
            >
              <img :src="glass" alt="" class="eqit" />
            </div>
          </div>
        </div>
        <div class="right">
          <span @click="handleCall(item)"> 呼叫 </span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import svgIcon from '../svgIcon.vue'
import avatar from '../../icons/svg/userAvatar.svg'
import pc from '../../assets/img/pc.svg'
import android from '../../assets/img/phone.svg'
import glass from '../../assets/img/glasses.svg'
import { contactList, customCmd } from '../../api/index.js'

export default {
  components: {
    svgIcon,
  },
  data() {
    return {
      pc: pc,
      glass: glass,
      avatar: avatar,
      android: android,
      // regExp: ,
      linkName: '',
      list: [],
      timer:null
    }
  },
  computed: {
    room() {
      return this.$store.state.roomInfo
    },
    user() {
      return this.$store.state.user
    },
  },
  mounted() {
    this.getContactList()
  },
  beforeDestroy(){
    clearTimeout(this.timer)
  },
  methods: {
    onRegExpTest(str) {
      let arr = 'Android,glass'.split(',')

      let exp = /Websocket|Android|glass|Android,glass|Websocket,Android,glass/
      let rtnStr = exp.exec(str)[0]
      return rtnStr
    },
    /**
     * 获取用户列表
     */
    getContactList() {
      let data = {
        token: this.$store.state.user.userId,
        likeName: this.linkName,
      }
      console.log(data)
      contactList(data).then((res) => {
        console.log(res)
        this.list = res.data
        this.list.forEach((item) => {
          if (item.os == 'Websocket') {
            item.type = 1
          } else if (item.os == 'Android') {
            item.type = 2
          } else if (item.os == 'glass') {
            item.type = 3
          } else if (item.os == 'Android,glass') {
            item.type = 4
          } else if (item.os == 'Websocket,Android') {
            item.type = 5
          } else if (item.os == 'Websocket,Android,glass') {
            item.type = 6
          }
        })
      })
      this.timer = setTimeout(this.getContactList,2000)
    },
    async handleCall(params) {
      let data = {
        token: this.user.userId,
        callType: 0,
        openCamera: true,
        roomId: this.room.roomId,
        toUserId: params.userId,
      }
      customCmd(data).then((res) => {
        if (res.success) {
          this.$store.commit('TYPE_TARGEUSER', {
            userId: params.userId,
            userName: params.username,
          })
          this.$store.commit('TYPE_TARGEROOM', { roomId: this.room.roomId })
          this.$store.commit('TYPE_CALLPAGEVISIBLE', true)
          this.$store.commit('TYPE_CANCEL', true)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
  margin-bottom: 12px;
  padding-top: 12px;
  box-sizing: border-box;
}
nav {
  width: 100%;
  height: 30px;
  padding: 0 12px;
  margin: 0px 0 12px;
  box-sizing: border-box;
}
.list {
  height: calc(100% - 40px);
  overflow-y: auto;
  padding: 0 12px;
  li {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #081d43;

    .left {
      display: flex;
      align-items: center;
      .avatar {
        width: 15px;
        height: 15px;
        margin-right: 9px;
        .svg {
          width: 100%;
          height: 100%;
          display: inline-block;
          color: var(--al-text-color1);
        }
      }
      span {
        max-width: 70px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: var(--al-text-color1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
      }
      .eqit-group {
        display: flex;
        align-items: center;
        margin-left: 20px;
        :nth-child(2) {
          margin: 0 10px;
        }
        .eqit {
          width: 16px;
          height: 12px;
          img {
            width: 100%;
            height: auto;
            display: inline-block;
          }
        }
      }
    }
    .right {
      span {
        display: inline-block;
        text-align: center;
        color: var(--al-text-color2);
        cursor: pointer;
        user-select: none;
        padding: 5px 16px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        background: var(--al-main-02-color);
        border-radius: 10px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
/deep/.el-input {
  // 输入框高度
  height: 30px;
  // 边框圆角
}
/deep/.el-input__inner {
  padding: 0;
  height: 30px;
  // background: var(--al-component-bg-color);
  background: rgba(254, 254, 254, 0.05);
  // 设置字号
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  // 设置输入字体的颜色
  color: var(--al-text-color1);
  border: 0;
  padding: 0 8px;
  border-radius: 15px;
}
/deep/.el-input__inner::-webkit-input-placeholder {
  /* WebKit browsers，webkit内核浏览器 */
  color: rgba(172, 185, 231, 0.5);
  font-size: 12px;
  box-sizing: border-box;
}
</style>
