<template>
  <div class="mark-container">
    <div class="mark-inline-container" ref="container">
      <img
        :src="background"
        :style="backgroundStyle"
        @load="handleImgLoad"
        class="mark-background"
      />
      <div class="sketch-wrap">
        <sketch-board
          class="sketch-board"
          :width="board.width"
          :height="board.height"
          :config="board.config"
          :opacity="0.2"
          :on-load="loadedSketch"
          @inputText="handleInputText"
          @syncNodes="handleSyncNodes"
          :ignore="board.ignore"
          :baseWidth="baseWidth"
          :baseHeight="baseHeight"
          :userId="userId"
        />
        <el-input
          size="mini"
          class="text-input"
          v-if="input.show"
          v-model="input.value"
          :style="{ top: `${input.y}px`, left: `${input.x}px` }"
        >
          <el-button slot="append" @click="handleInput">确定</el-button>
        </el-input>
      </div>

      <div class="tools">
        <tool
          :config.sync="board.config"
          @clear="clearSVG"
          @erase="handleErase"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { Messages } from '../../thirdParty/RongClient'
import SketchBoard from './Board.vue'
import Tool from './Tool.vue'
import Emitter from '../../thirdParty/Emitter'
export default {
  name: 'App',
  components: {
    SketchBoard,
    Tool,
  },
  props: ['background', 'baseWidth', 'baseHeight','userId'],
  computed: {
    ...mapState(['rongClient', 'roomInfo']),
  },
  watch: {
    'board.config'() {
      this.input.show = false
    },
  },
  data() {
    return {
      board: {
        width: 0,
        height: 0,
        config: {
          thickness: 'middle',
          pen: 'pencil', // highlighters
          line: 'curve',
          color: '#ff1000',
          fontSize: 15,
          fontColor: '#ff1000',
          mode: 1,
        },
        ignore: false,
      },
      input: {
        show: false,
        x: 0,
        y: 0,
        value: '',
      },
      backgroundStyle: '',
    }
  },
  created() {
    Emitter.on('drawMark', this.handleDrawMark)
    Emitter.on('resizeBackground', this.handleResizeBackground)
  },
  mounted() {
    this.$nextTick(this.handleResizeBackground)
  },
  methods: {
    async handleResizeBackground(from) {
      const mainVideoElement = document.querySelector(
        '#videoMainContainer video'
      )
      if (mainVideoElement) {
        this.backgroundStyle = mainVideoElement.getAttribute('style')
        setTimeout(this.handleImgLoad, 500)
        // this.handleImgLoad()
      }
    },
    async handleImgLoad() {
      if (this.$refs.container) {
        this.board.width = this.$refs.container.offsetWidth
        this.board.height = this.$refs.container.offsetHeight
      }
    },
    handleInputText(x, y) {
      const scale = this.board.width / this.baseWidth
      this.input = {
        show: true,
        x: x * scale,
        y: y * scale,
      }
    },
    loadedSketch(sketch) {
      this.sketch = sketch
    },
    clearSVG() {
      // this.handleSendMark({ type: "clear" });
      this.sketch.clear()
    },
    handleErase() {
      // this.handleSendMark({ type: "revoke" });
      this.sketch.revoke()
      // this.board.config.mode = 0;
    },
    handleInput() {
      if (this.input.value) {
        this.sketch.insertText(this.input.value)
        this.input = {
          show: false,
          x: 0,
          y: 0,
          value: '',
        }
      }
    },
    handleSyncNodes(nodes) {
      const data = {
        type: 'draw',
        nodes,
        windowWidth: this.baseWidth,
        windowHeight: this.baseHeight,
      }
      console.log(data)
      this.handleSendMark(data)
    },
    async handleSendMark(data) {
      if (!this.rongClient || !this.roomInfo.roomId) return
      const message = new Messages.MarkMessage(data)
      this.rongClient.sendCustomMessage(this.roomInfo.roomId, message)

      // console.log(this.$store.state);
    },
    handleDrawMark(data) {
      // if (data.type === "clear") {
      //   this.sketch.clear();
      // } else if (data.type === "revoke") {
      //   this.sketch.revoke();
      // } else
      if (data.type === 'draw') {
        this.sketch.fillNode(data)
      }
    },
    beforeDestroy() {
      Emitter.removeListener('drawMark', this.handleDrawMark)
      Emitter.removeListener('resizeBackground', this.handleResizeBackground)
    },
  },
}
</script>

<style lang="scss" scoped>
.mark-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.mark-inline-container {
  position: relative;
  overflow: hidden;
  z-index: 9;
}
.sketch-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}
.sketch-board {
  position: relative;
  z-index: 1;
}
.tools {
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}
.text-input {
  position: absolute;
  width: 160px;
  top: 100px;
  background: #fff;
  z-index: 9;
}

.mark-background {
  // width: 100%;
  // position: absolute;
  // top:0;
  // left: 0;
}
</style>
