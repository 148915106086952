// 三种笔粗细-铅笔
export const thicknessMap = {
  'small': '2',
  'middle': '4',
  'large': '8',
};

// 三种笔粗细-荧光笔
export const hThicknessMap = {
  'small': '5px',
  'middle': '10px',
  'large': '20px',
};

// 笔型(铅笔与荧光笔)
export const penStyles = ['pencil', 'highlighters'];

// 线型
export const lines = ['curve', 'stright','square','ellipse','text'];

// 画板模式 0:删除模式 1:绘制模式 2:选择模式(暂无)
export const modes = [0, 1, 2];

// 画板选择器上的各种颜色
export const colors = ['#000000', '#ff1000', '#326ed9', '#ffc510', '#306c00', '#ff1ecf'];
