<template>
  <el-dialog
    title="猪"
    :visible.sync="visible"
    width="600"
    :close-on-click-modal="false"
    custom-class="select-pig-modal"
  >
    <i class="el-icon-circle-close close-icon" @click="handleClose"></i>

    <pig-card :data="pigs[0] || {}" />
    <div class="select-btns">
      <div class="select-btn" @click="handleChoose(pigs[0], true)">选中</div>
      <div class="select-btn give-up" @click="handleChoose(pigs[0], false)">
        放弃
      </div>
    </div>
  </el-dialog>
</template>

<script>
import lodash from 'lodash'
import { Messages } from '../../thirdParty/RongClient'
import emitter from '../../thirdParty/Emitter'
import PigCard from '../PigCard.vue'
import { rejectPig, choosePig } from '../../api'
export default {
  components: {
    PigCard,
  },
  data() {
    return {
      visible: false,
      pigs: [],
    }
  },
  created() {
    emitter.on('selectPig', this.handleSelectPig)
  },
  methods: {
    handleSelectPig(data) {
      // 打开浮层 确认数据
      if (data.type == 'confirm') {
        const pigs = data.data || []
        if (!pigs.length) {
          return
        }
        this.visible = true
        this.pigs = this.pigs.concat(pigs)
      } else if (data.type == 'select') {
        emitter.emit('refreshPigList')
        // 有人选择过了
        const selectedId = data.data.pigId
        const pigs = this.pigs.filter((pig) => pig.id !== selectedId)
        if (pigs.length) {
          this.pigs = pigs
        } else {
          this.visible = false
          this.pigs = []
        }
      }
      console.log('im 传过来的选猪消息', data, this.pigs)
    },
    async handleChoose(item, selected) {
      let roomId = this.$store.state.roomInfo.roomId
      let rongClient = this.$store.state.rongClient

      let res
      // 通知后端
      if (selected) {
        res = await choosePig({
          pigId: item.id,
          roomId,
        })
      } else {
        res = await rejectPig({
          pigId: item.id,
          roomId,
        })
      }

      if (res.success) {
        emitter.emit('refreshPigList')
        // 通知别人
        const message = new Messages.PigMessage({
          type: 'select',
          sendTime: Date.now(),
          data: {
            pigId: item.id,
            selected: selected,
          },
        })
        rongClient.sendCustomMessage(roomId, message)

        const pigs = this.pigs.filter((i) => i.id !== item.id)
        if (pigs.length) {
          this.pigs = pigs
        } else {
          this.visible = false
          this.pigs = []
        }
      }
    },
    handleClose() {
      this.visible = false
      // this.pigs = []
    },
  },

  beforeDestroy() {
    emitter.removeListener('selectPig', this.handleSelectPig)
  },
}
</script>
<style lang="scss" scoped>
.arrow-btn {
  position: absolute;
  top: 50%;
  width: 22px;
  height: 25px;
  background: red;
  transform: translateY(-50%);
  cursor: pointer;
  &.arrow-left {
    left: 40px;
    background: url('../../assets/img/piginfo_botton_left.png') no-repeat center
      center/contain;
  }
  &.arrow-right {
    right: 40px;
    background: url('../../assets/img/piginfo_botton_right.png') no-repeat
      center center/contain;
  }
}
.close-icon {
  font-size: 22px;
  color: var(--al-text-color2);
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}
.select-btns {
  position: absolute;
  bottom: 58px;
  left: 156px;
  display: flex;
}
.select-btn {
  width: 120px;
  height: 40px;
  border: 1px solid var(--al-border-color);
  border-radius: 3px;
  color: var(--al-text-color2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  &.give-up {
    margin-left: 20px;
    // background: var(--al-component-bg-03-color);
    // border: 1px solid var(--al-border-color);
  }
  &:hover{
    background: var(--al-main-color);
  }
}
</style>
<style lang="scss">
.select-pig-modal {
  width: 800px;
  height: 473px;
  background: var(--al-dialog-bg-color);
  border: 1px solid var(--al-border-color);
  border-radius: 5px;
  .el-carousel__arrow--left {
    left: -22px;
    background: red;
  }
  .el-carousel__arrow--left {
    right: -22px;
  }
  .el-carousel__container {
    height: 473px;
  }
  .el-dialog__body {
    padding: 0 62px;
  }
  .pid-detail-container {
    padding-top: 71px;
  }
  .carousel-item {
  }
  .el-dialog__header {
    display: none;
  }
  .el-carousel__item {
    padding: 0 20px;
  }
}
</style>
<style lang="scss" scoped></style>
