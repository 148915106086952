<template>
  <el-aside class="aside-wrap">
    <div class="aside">
      <div class="container">
        <!-- <keep-alive> -->
        <component :is="componentId"></component>
        <!-- </keep-alive> -->
      </div>
      <div class="top">
        <!-- :class="{ highLight: whichIndex == index }" -->

        <div
          :class="whichIndex == index ? 'crad-bg' : 'crad'"
          v-for="(item, index) in cardArr"
          :key="index"
          @click="handleSwitchTab(index, item.componentId)"
        >
          <!-- <img :src="item.componentSrc" alt="" srcset="" /> -->
          <svg-icon :icon-class="item.componentSrc" class="svg"></svg-icon>
        </div>
        <!-- <div class="line" :style="'left: ' + 90 * whichIndex + 'px'"></div> -->
      </div>
    </div>
    <select-pig-modal />
  </el-aside>
</template>
<script>
import Chat from './Chat.vue'
import AddressBook from './AddressBook.vue'
import Part from './Part.vue'
import SelectionPig from './SelectionPig.vue'
import SelectPigModal from './SelectPigModal.vue'
import svgIconVue from '../svgIcon.vue'

// import right_message from '../../assets/img/right_message.svg'
// import right_user from '../../assets/img/right_user.svg'
// import right_book from '../../assets/img/right_book.svg'
// import right_pig from '../../assets/img/right_pig.svg'
export default {
  components: {
    Chat,
    Part,
    AddressBook,
    SelectionPig,
    SelectPigModal,
    svgIconVue,
  },
  data() {
    return {
      whichIndex: 0,
      componentId: 'Chat',
      cardArr: [
        {
          componentName: '聊天',
          componentSrc: 'right_message',
          componentId: 'Chat',
        },
        {
          componentName: '参会者',
          componentSrc: 'right_user',
          componentId: 'Part',
        },
        {
          componentName: '通讯录',
          componentSrc: 'right_book',
          componentId: 'AddressBook',
        },
        {
          componentName: '选猪',
          componentSrc: 'right_pig',
          componentId: 'SelectionPig',
        },
      ],
    }
  },
  mounted() {

    this.handleSwitchTab(1,'Part')
  },
  methods: {
    handleSwitchTab(index, componentId) {
      this.whichIndex = index
      this.componentId = componentId
      if (componentId === 'Part') {
        this.$emit('refreshUserAndStreamType')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.el-aside {
  // width: 373px !important;
  width: 317px !important;
  // height: 533px;
}
.aside {
  width: 317px;
  height: 100%;
  // border: 1px solid #ccc;
  box-sizing: border-box;
  // background: var(--al-component-bg-05-color);
  background: rgba(254, 254, 254, 0.05);
  border-left: 1px solid rgba(254, 254, 254, 0.08);
  // border-radius: 10px;
  display: flex;
  // flex-direction: column;
}
.top {
  width: 47px;
  // height: 100%;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  // justify-content: space-around;
  align-items: center;
  position: relative;
  // border-radius: 10px 10px 0px 0px;
  padding-top: 9px;
  // background: #ffffff;
  // opacity: 0.1;

  .crad-bg,
  .crad {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    .svg {
      width: 20px;
      height: 20px;
      display: inline-block;
      color: var(--al-text-color2);
    }
  }
  .crad-bg {
    background: var(--al-main-color);
    // color: var(--al-main-color);
    opacity: 1;
  }
  .line {
    width: 60px;
    height: 1px;
    background: #409eff;
    position: absolute;
    bottom: 0;
    left: 5px;
    transition: all 0.3s;
  }
}
.container {
  // width: 100%;
  width: 270px;
  height: calc(100% - 8px);
  transition: all 0.3s;
}
</style>
