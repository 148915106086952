<template>
  <div class="wrap">
    <div class="as-header">
      <div class="as-header-l">
        <div class="theme">
          <span>主题：</span>
          <em> {{ titleName }}</em>
        </div>
        <div class="theme">
          <span>会议ID：</span>
          <em ref="roomIdEl" class="sp1"> {{ roomId }}</em>
          <img :src="copy" alt="" @click="onCopy" />
        </div>
      </div>
    </div>
    <el-container>
      <Main
        :unPublishTrack="unPublishTrack"
        :emptyStore="emptyStore"
        :getRoomUserAndstreamType="getRoomUserAndstreamType"
        :onRongLevae="onRongLevae"
        :unSubscribeTracks="unSubscribeTracks"
      />
      <Aside @refreshUserAndStreamType="getRoomUserAndstreamType" />
    </el-container>
    <pig-modals
      :visible="pigDetailVisible"
      :pigs="pigList"
      :type="pigType"
      @close="pigDetailVisible = false"
      @refreshPigList="getNoOperationPigList"
    />
    <!-- :type="tabType"
      :index="pigIndex" -->
  </div>
</template>
<script>
import Main from '../components/PlayMain/index.vue'
import Aside from '../components/Aside/index'
import Emitter from '../thirdParty/Emitter'
import PigModals from '../components/Aside/PigModals.vue'
import copy from '../assets/img/copy.svg'
import { getPigList } from '../api/index'

import {
  mediaToken,
  contactList,
  getUserInfo,
  queryRoomMembers,
} from '../api/index'
export default {
  components: {
    Main: Main,
    Aside: Aside,
    PigModals,
  },
  data() {
    return {
      pigDetailVisible: false,
      copy: copy,
      rongToken: '',
      // user: '',
      pigList: [],
      pigType: 2,
      userId: '',
    }
  },
  computed: {
    titleName() {
      return this.$store.state.roomInfo.roomName
    },
    user() {
      return this.$store.state.user
    },
    roomId() {
      return this.$store.state.roomInfo.roomId
    },
    whetherJoinRoom() {
      return this.$store.state.whetherJoinRoom
    },
    rtcRoom() {
      return this.$store.state.rtcClient.getRoom()
    },
    rcRtcCode() {
      return this.$store.state.rtcClient.getRCRTCCode()
    },
    rtcClient() {
      return this.$store.state.rtcClient.getRtcClient()
    },
    imClient() {
      return this.$store.state.rongClient.getImLib()
    },
    device() {
      return this.$store.state.rtcClient.getDevice()
    },
    mediaStreamList() {
      let list = this.$store.state.mediaStreamList
      return list
    },
    audioMediaStreamList() {
      let list = this.$store.state.audioMediaStreamList
      return list
    },
  },
  watch: {
    whetherJoinRoom(val, old) {
      if (val) {
        this.joinChatRoom(this.roomId)
      }
    },
    audioMediaStreamList(val) {
      if (val) {
        val.forEach((item) => {
          item.play()
        })
      }
    },
  },
  created() {
    if (!this.roomId) {
      // this.$router.push('/index')
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      //初始化页面先退出会议在加入会议

      //初始化页面即加入会议
      if (this.rtcRoom) {
        let result = await this.onRongLevae()
        if (result) {
          this.emptyStore(false, false)
          this.joinChatRoom(this.roomId)
          this.getNoOperationPigList()
        }
      } else {
        this.emptyStore(false, false)
        this.joinChatRoom(this.roomId)
      }
    },
    async getNoOperationPigList() {
      const query = {
        roomId: this.roomId,
      }
      const res = await getPigList(query)
      this.pigList = res.data
      if (this.pigList.length) {
        this.pigDetailVisible = true
      }else{
         this.pigDetailVisible = false
      }
    },
    /**
     * 通过userId查询用户
     * @param {*} userId
     */
    getUserInfo(userId) {
      let data = {
        token: this.user.userId,
        userId,
      }
      return new Promise((resolve, reject) => {
        getUserInfo(data).then((res) => {
          resolve(res.data)
        })
      })
    },
    onCopy() {
      let el = this.$refs.roomIdEl.innerHTML
      // 创建输入框元素
      let oInput = document.createElement('input')
      //去除空格
      let rmEl = el.replace(/\s*/g, '')
      // 将想要复制的值
      oInput.value = rmEl
      console.log(rmEl)
      document.body.appendChild(oInput)
      // 选中输入框
      oInput.select()

      document.execCommand('copy')

      // 删除临时实例
      document.body.removeChild(oInput)
      this.$message.success('复制成功')
    },
    /**
     * IM加入聊天室
     */
    async joinChatRoom(roomId) {
      let chatRoomResult, rtcRoomResult, userCountResult
      chatRoomResult = await this.$store.state.rongClient.joinChatRoom(roomId)
      //rtc加入房间
      if (chatRoomResult) {
        rtcRoomResult = await this.$store.state.rtcClient.joinRTCRoom(roomId)
      }
      // //房间监听器
      if (rtcRoomResult) {
        let roomTracks = this.$store.state.rtcClient.getTracks()
        if (roomTracks.length > 0) {
          this.subscribe(roomTracks)
        }
        //注册rtc事件监听器
        this.RtcEventListener()
        //获取媒体资源并发布
        this.getMediaStream()
      }
    },
    /**
     * 查询房间内用户
     */
    async getRoomUserAndstreamType() {
      if (this.roomId) {
        const userArr = await queryRoomMembers(this.roomId)
        const arr = userArr
        arr.forEach((item, index) => {
          if (this.user.userId == item.id) {
            arr.unshift(arr.splice(index, 1)[0])
          }
        })
        this.$store.commit('TYPE_MEMBERLIST', arr)
      }
    },
    getMediaStream() {
      this.getDeviceList()
      this.$store.state.rtcClient.audioTrack().then(async (track) => {
        this.$store.commit('TYPE_MICROPHONESTREAM', track)
        // this.$store.state.rtcClient.publish(track)
        const { code } = await this.rtcRoom.publish([track])
        if (code == this.rcRtcCode.SUCCESS) {
          console.log('资源发布成功')
          console.log('加入房间并发布资源触发与会人员')
          console.log('1111111',track)
          this.getRoomUserAndstreamType()
          this.$store.dispatch('syncUserStatus',{track,flag:true})
        }

        // 若资源发布失败
        if (code !== this.rcRtcCode.SUCCESS) {
          console.log('资源发布失败:', code)
        }
      })
    },
    /**
     * 获取麦克风设备列表
     */
    async getDeviceList() {
      const microphones = await this.device.getMicrophones()
      if (microphones.length < 1) {
        this.$message.error('无音频设备')
      }
    },
    getLocalTracks() {
      let data = this.$store.state.rtcClient.getLocalTracks()
      console.log(data)
    },
    /**
     * IM获取聊天室信息
     */
    getChatRoomInfo() {
      let roomId = this.$store.state.roomInfo.roomId
      this.$store.state.rongClient.getChatRoomInfo(roomId)
    },
    async subscribe(tracks) {
      let { code } = await this.rtcRoom.subscribe(tracks)
      console.log(code)
      // 按业务需求选择需要订阅资源，通过 room.subscribe 接口进行订阅
      if (code !== 10000) {
        console.log('资源订阅失败 ->', code)
      }
    },
    /**
     * 取消所有资源的订阅
     */
    unSubscribeTracks() {
      const audioList = this.$store.state.mediaStreamList
      const videoList = this.$store.state.audioMediaStreamList
      if (audioList.length > 0) {
        audioList.forEach((item, index) => {
          this.onUnSubscribe(item)
        })
      }
      if (videoList.length > 0) {
        videoList.forEach((item, index) => {
          this.onUnSubscribe(item)
        })
      }
    },
    /**
     * 销毁己方发布的流
     */
    onStreamDestroy() {
      let audio = this.$store.state.microphoneStream
      let screen = this.$store.state.screenStream
      let video = this.$store.state.cameraStream
      if (audio) {
        audio.destroy()
      }

      if (screen) {
        screen.destroy()
      }

      if (video) {
        video.destroy()
      }
    },
    /**
     * 取消所有资源的发布
     */
    async unPublishTrack() {
      let audio = this.$store.state.microphoneStream
      let screen = this.$store.state.screenStream
      let video = this.$store.state.cameraStream

      let audioType, screenType, videoType
      if (audio) {
        audioType = await this.unPublish(audio)
        // if (audioType) {
        audio.destroy()
        // }
      }
      if (screen) {
        screenType = await this.unPublish(screen)
        // if (screenType) {
        screen.destroy()
        // }
      }
      if (video) {
        videoType = await this.unPublish(video)
        // if (videoType) {
        video.destroy()
        // }
      }
      if (audioType || screenType || videoType) {
        return true
      } else {
        return false
      }
    },
    /**
     * 取消发布资源
     * @param {*} track 资源
     */
    unPublish(track) {
      return new Promise(async (resolve, reject) => {
        // const { code } = await this.rtcRoom.unpublish([track])
        // console.log(code)
        // if (code == this.rcRtcCode.SUCCESS) {
        //   console.log('取消发布成功')
        //   resolve(true)
        // }
        // if (code !== this.rcRtcCode.SUCCESS) {
        //   console.log('取消发布失败:', code)
        //   reject(false)
        // }

        this.rtcRoom
          .unpublish([track])
          .then((res) => {
            console.log(res)
            if (res.code == this.rcRtcCode.SUCCESS) {
              console.log('取消发布成功')
              resolve(true)
            }
            if (res.code !== this.rcRtcCode.SUCCESS) {
              console.log('取消发布失败:', res.code)
              resolve(true)
            }
          })
          .catch((err) => {
            console.log('unPublish', err)
            resolve(true)
          })
      })

      // 取消发布后，业务层若不再需要播放资源，可调 destroy 方法销毁资源
      // audioTrack.destroy()
      // videoTrack.destroy()
    },
    /**
     * 取消资源订阅
     * @param {*} track 媒体流
     */
    async onUnSubscribe(track) {
      // const { code } = await this.rtcRoom.unsubscribe([track])
      // if (code == this.rcRtcCode.SUCCESS) {
      //   console.log('取消订阅成功')
      // }
      // if (code !== this.rcRtcCode.SUCCESS) {
      //   console.log('取消订阅失败:', code)
      // }
      this.rtcRoom
        .unsubscribe([track])
        .then((res) => {
          if (res.code == this.rcRtcCode.SUCCESS) {
            console.log('取消订阅成功')
          }
          if (res.code !== this.rcRtcCode.SUCCESS) {
            console.log('取消订阅失败:', res.code)
          }
        })
        .catch((err) => {
          console.err('onUnSubscribe', err)
        })
      // 取消订阅完成后，业务层移除相关的 UI 信息即可
    },
    /**
     * 初始化置空store
     */
    async emptyStore(type = true, roomType = true) {
      //置空roomInfo
      if (roomType) {
        this.$store.commit('TYPE_ROOMINFO', { roomId: '', roomName: '' })
      }

      //置空 messageList 消息列表
      this.$store.commit('TYPE_MESSAGELIST', [])
      //置空 mediaList		视频媒体资源
      this.$store.commit('TYPE_MEDIASTREAMLIST', [])
      //置空 	音频媒体资源
      this.$store.commit('TYPE_AUDIOMEDIASTREAMLIST', [])
      //音频
      this.$store.commit('TYPE_MICROPHONESTREAM', null)
      //视频
      this.$store.commit('TYPE_CAMERASTREAM', null)
      //屏幕共享
      this.$store.commit('TYPE_SCREENSTREAM', null)

      //置空主视频
      this.$store.commit('TYPE_MEDIASTREAMMAIN', null)
      //参会人员
      this.$store.commit('TYPE_MEMBERLIST', [])

      //来点人员信息
      this.$store.commit('TYPE_TARGEUSER', {})
      //来电房间信息
      this.$store.commit('TYPE_TARGEROOM', {})
      //是否加入房间
      this.$store.commit('TYPE_WHETHERJOINROOM', false)
      if (type) {
        this.$router.push('/index')
      }
    },
    /**
     * RTC房间事件监听器
     */
    RtcEventListener() {
      let _this = this
      let listener = {
        /**
         * 本端被踢出房间时触发
         * @description 被踢出房间可能是由于服务端超出一定时间未能收到 rtcPing 消息，所以认为己方离线。
         * 另一种可能是己方 rtcPing 失败次数超出上限，故而主动断线
         * @param byServer
         * 当值为 false 时，说明本端 rtcPing 超时
         * 当值为 true 时，说明本端收到被踢出房间通知
         */
        async onKickOff(byServer) {
          // console.error('onKickOff', byServer)
          if (byServer) {
            _this.onStreamDestroy()
            _this.emptyStore()
          }
          // 当本地已获取资源后，需要调用 track.destroy() 销毁已获取的资源， track 为 RCMicphoneAudioTrack 或 RCCameraVideoTrack 类型实例
        },
        /**
         * 接收到房间信令时回调，用户可通过房间实例的 `sendMessage(name, content)` 接口发送信令
         * @param name 信令名
         * @param content 信令内容
         * @param senderUserId 发送者 Id
         * @param messageUId 消息唯一标识
         */
        onMessageReceive(name, content, senderUserId, messageUId) {
          console.log(
            'onMessageReceive',
            name,
            content,
            senderUserId,
            messageUId
          )
        },
        /**
         * 监听房间属性变更通知
         * @param name
         * @param content
         */
        onRoomAttributeChange(name, content) {
          console.log('onRoomAttributeChange', name, content)
        },
        /**
         * 发布者禁用/启用音频
         * @param audioTrack RCRemoteAudioTrack 类实例
         */
        onAudioMuteChange(audioTrack) {
          console.log('onAudioMuteChange', audioTrack)
           console.log('22222222',audioTrack._remoteMuted)
          _this.getRoomUserAndstreamType()
           _this.$store.dispatch('syncUserStatus',{track:audioTrack,flag:!audioTrack._remoteMuted})
        },
        /**
         * 发布者禁用/启用视频
         * @param videoTrack RCRemoteVideoTrack 类实例对象
         */
        onVideoMuteChange(videoTrack) {
          console.log('onVideoMuteChange', videoTrack)
           console.log('33333333')
          _this.getRoomUserAndstreamType()
          //  this.$store.dispatch('syncUserStatus',{track:videoTrack,flag:true})

        },
        /**
         * 房间内其他用户新发布资源时触发
         * 如需获取加入房间之前房间内某个用户发布的资源列表，可使用 room.getRemoteTracksByUserId('userId') 获取
         * @param tracks 新发布的音轨与视轨数据列表，包含新发布的 RCRemoteAudioTrack 与 RCRemoteVideoTrack 实例
         */
        async onTrackPublish(tracks) {
          console.log(tracks, 'onTrackPublish')
           console.log('444444444')
          _this.subscribe(tracks)
          _this.getRoomUserAndstreamType()
           _this.$store.dispatch('syncUserStatus',{track:tracks,flag:true})

        },
        /**
         * 房间用户取消发布资源
         * @param tracks 被取消发布的音轨与视轨数据列表
         * @description 当资源被取消发布时，SDK 内部会取消对相关资源的订阅，业务层仅需处理 UI 业务
         */
        onTrackUnpublish(tracks) {
          console.log('onTrackUnpublish', tracks)
          let arr = _this.mediaStreamList
          let audioArr = _this.audioMediaStreamList
          //用户取消发布视频资源的处理
          arr.forEach((item, index) => {
            for (let i = 0; i < tracks.length; i++) {
              if (tracks[i]._kind == 'video') {
                if (item._id == tracks[i]._id) {
                  arr.splice(index, 1)
                  if (tracks[i]._id == _this.$store.state.mediaStreamMain._id) {
                    _this.$store.commit('TYPE_MEDIASTREAMMAIN', arr[0])
                  }
                }
              }
            }
          })
          //取消发布音频资源的处理删除
          audioArr.forEach((item, index) => {
            for (let i = 0; i < tracks.length; i++) {
              if (tracks[i]._kind == 'audio') {
                if (item._id == tracks[i]._id) {
                  tracks[i].destroy()
                  audioArr.splice(index, 1)
                }
              }
            }
          })

          _this.$store.commit('TYPE_MEDIASTREAMLIST', arr)
          _this.$store.commit('TYPE_AUDIOMEDIASTREAMLIST', audioArr)
           console.log('555555555')
          _this.getRoomUserAndstreamType()
           _this.$store.dispatch('syncUserStatus',{track:tracks,flag:false})

        },
        /**
         * 订阅的音视频流通道已建立, track 已可以进行播放
         * @param track RCRemoteTrack 类实例
         */
        onTrackReady(track) {
           console.log('888888888888')
          console.log(1111111111,'onTrackReady', track)
           
          
          if (track.isAudioTrack()) {
            // 音轨不需要传递播放控件
            // track.play()
            let arr = _this.audioMediaStreamList
            arr.push(track)
            _this.$store.commit('TYPE_AUDIOMEDIASTREAMLIST', arr)
            _this.$store.dispatch('syncUserStatus',{track,flag:!track._remoteMuted })
          } else {
            // 视轨需要一个 video 标签才可进行播放
            // const element = document.createElement('video')
            // document.body.appendChild(element)
            // track.play(element)
            _this.$store.dispatch('syncUserStatus',{track,flag:true})
            let arr = _this.mediaStreamList
            arr.push(track)
            _this.$store.commit('TYPE_MEDIASTREAMLIST', arr)
            //若主视频没有流则使用最新发布的
            if (!_this.$store.state.mediaStreamMain) {
              _this.$store.commit('TYPE_MEDIASTREAMMAIN', track)
            }
            //若流为屏幕共享
            if (track._tag == 'screenshare') {
              _this.$store.commit('TYPE_MEDIASTREAMMAIN', track)
            }
          }
        },
        /**
         * 人员加入
         * @param userIds 加入的人员 id 列表
         */
        onUserJoin(userIds) {
          console.log('onUserJoin', userIds)
           console.log('66666666666')
          // _this.getRoomUserAndstreamType()
          _this.$store.dispatch('syncUserStatus',{userIds,mode:'add'})

        },
        /**
         * 人员退出
         * @param userIds
         */
        onUserLeave(userIds) {
          console.log('onUserLeave', userIds)
          _this.$store.dispatch('syncUserStatus',{userIds,mode:'delete'})

          let arr = _this.mediaStreamList
          let audioArr = _this.audioMediaStreamList
          arr.forEach((item, index) => {
            if (item._userId == userIds[0]) {
              arr.splice(index, 1)
            }
          })
          audioArr.forEach((item, index) => {
            if (item._userId == userIds[0]) {
              item.destroy()
              audioArr.splice(index, 1)
            }
          })
          _this.$store.commit('TYPE_MEDIASTREAMLIST', arr)
          _this.$store.commit('TYPE_AUDIOMEDIASTREAMLIST', audioArr)
           console.log('777777777777')
          _this.getRoomUserAndstreamType()

        },
      }
      this.$store.state.rtcClient.registerRoomEventListener(listener)
      this.$store.state.rtcClient.roomEventListener()
    },
    /**
     * rong IM RTC 退出房间
     */
    onRongLevae() {
      return new Promise((resolve, reject) => {
        this.imClient.quitChatRoom(this.roomId).then(async (res) => {
          console.log('quitChatRoom成功', this.roomId, this.rtcClient.leaveRoom)
          const { code } = await this.rtcClient.leaveRoom(this.rtcRoom)
          console.log('leaveRoom成功')
          if (code == this.rcRtcCode.SUCCESS) {
            console.log('离开房间成功')
          }
          // 退出聊天室成功
          if (res.code === 0) {
            console.log('退出聊天室 ' + this.roomId)
          }
          if (code == this.rcRtcCode.SUCCESS && res.code === 0) {
            resolve(true)
          }
        })
      })
    },
  },
  beforeRouteLeave(to, from, next) {
    // ...
    // Emitter.emit('onLeave', false)

    this.unPublishTrack(), this.unSubscribeTracks()
    this.emptyStore(false)
    next()
  },
}
</script>
<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: calc(100%);
  // padding: 0 20px 20px;
  box-sizing: border-box;
  position: relative;
}
.posa {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
}
.as-header {
  width: 100%;
  height: 47px;
  background: rgba(0, 0, 0, 0);
  line-height: 47px;
  padding: 0 20px;
  box-sizing: border-box;
  .as-header-l {
    margin-top: 27px;
    float: left;
    display: flex;
    .theme {
      font-size: 18px;
      margin-right: 20px;
      span {
        // width: 28px;
        height: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: var(--al-text-color1);
        opacity: 0.3;
      }
      em {
        // width: 120px;
        display: inline-block;
        height: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: var(--al-text-color1);
      }
      .sp1 {
        width: 100px;
      }
      img {
        width: 13px;
        height: 13px;
        display: inline-block;
        cursor: pointer;
      }
    }
  }
  // .as-header-c {
  //   width: 203px;
  //   margin: 0 auto;
  //   // display: flex;
  //   position: relative;
  //   :nth-child(1) {
  //     margin-right: 63px;
  //   }
  //   span {
  //     width: 80px;
  //     // background: red;
  //     font-size: 16px;
  //     font-family: Microsoft YaHei;
  //     font-weight: 400;
  //     color:var(--al-text-color1);;
  //     // display: inline-block;
  //     user-select: none;
  //     cursor: pointer;
  //   }
  //   i {
  //     position: absolute;
  //     bottom: 3px;
  //     left: 0;
  //     display: inline-block;
  //     width: 65px;
  //     height: 3px;
  //     background: #00c8ff;
  //     border-radius: 2px;
  //     transition: all 0.3s;
  //   }

  //   .spanBg {
  //     opacity: 0.5;
  //   }
  // }
}
.el-container {
  width: 100%;
  height: calc(100% - 47px - 30px);
  box-sizing: border-box;
  padding: 0 20px 20px;
}
</style>
