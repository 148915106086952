<template>
  <div>
    <div class="popover-wrap">
      <ul class="list">
        <li
          class="tool-item"
          :class="{ active: config.line === 'curve' }"
          @click="_handle('line', 'curve')"
          title="画笔"
        >
          <el-popover placement="right" trigger="manual" v-model="lineVisible">
            <ul class="options">
              <li
                v-for="item of Object.keys(thicknessMap)"
                :key="item"
                :class="[
                  'option-item',
                  item,
                  config.thickness === item && 'on',
                ]"
                @click="_handle('thickness', item)"
              >
                <span
                  class="point"
                  :style="{ backgroundColor: config.color }"
                ></span>
              </li>
            </ul>
            <ul class="options">
              <li
                v-for="item of colors"
                :key="item"
                :class="['option-item', item, config.color === item && 'on']"
                @click="_handle('color', item)"
              >
                <span class="color-round" :style="{ background: item }"></span>
              </li>
            </ul>
            <p class="label iconfont icon-huabi" slot="reference"></p>
          </el-popover>
        </li>
        <li
          class="tool-item"
          :class="{ active: config.line === 'stright' }"
          @click="_handle('line', 'stright')"
           title="直线"
        >
          <p class="label iconfont icon-zhixian"></p>
        </li>
        <li
          class="tool-item"
          :class="{ active: config.line === 'arrow' }"
          @click="_handle('line', 'arrow')"
           title="箭头"
        >
          <p class="label iconfont icon-jianxing"></p>
        </li>
        <li
          class="tool-item"
          :class="{ active: config.line === 'square' }"
          @click="_handle('line', 'square')"
           title="矩形"
        >
          <p class="label iconfont icon-juxing"></p>
        </li>
        <li
          class="tool-item"
          :class="{ active: config.line === 'ellipse' }"
          @click="_handle('line', 'ellipse')"
           title="椭圆"
        >
          <p class="label iconfont icon-tuoyuanxing"></p>
        </li>
        <li
          class="tool-item"
          :class="{ active: config.line === 'text' }"
          @click="_handle('line', 'text')"
           title="文字"
        >
          <el-popover placement="right" trigger="manual" v-model="textVisible">
            <el-select :value="config.fontSize" @change="handleFontsSizeChange">
              <el-option :value="15" label="小">小</el-option>
              <el-option :value="30" label="中">中</el-option>
              <el-option :value="45" label="大">大</el-option>
            </el-select>
            <ul class="options">
              <li
                v-for="item of colors"
                :key="item"
                :class="[
                  'option-item',
                  item,
                  config.fontColor === item && 'on',
                ]"
                @click="_handle('fontColor', item)"
              >
                <span class="color-round" :style="{ background: item }"></span>
              </li>
            </ul>
            <p class="label iconfont icon-wenben" slot="reference"></p>
          </el-popover>
        </li>
        <li class="tool-item" @click="handleClear('erase')"  title="撤回"> 
          <p class="label iconfont icon-chexiao" slot="reference"></p>
        </li>
        <li class="tool-item" @click="handleClear('clear')"  title="清空">
          <p class="label iconfont icon-shanchu" slot="reference"></p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { thicknessMap, penStyles, lines, colors } from "./config";

export default {
  name: "ErasePopover",
  props: {
    colors: {
      // 颜色集合
      type: Array,
      default: () => colors,
    },
    config: {
      // 选项框配置项
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lineMap: {
        stright: "直线",
        curve: "手绘",
        square: "正方形",
        ellipse: "椭圆形",
        text: "文字",
      },
      tools: [
        {
          type: "erase",
          text: "擦除",
        },
        {
          type: "clear",
          text: "清屏",
        },
      ],
      current: "erase",
      thicknessMap,
      penStyles,
      lines,
      lineVisible: false,
      textVisible: false,
    };
  },
  methods: {
    handleFontsSizeChange(v) {
      this._handle("fontSize", v);
    },
    handleClear(type) {
      this.current = type;
      this.$emit(type);
    },
    _handle(type, value) {
      if (type === "line") {
        if (value === this.config.line) {
          if (value === 'text') {
            this.textVisible = !this.textVisible;
            this.lineVisible = false;
          } else {
            this.lineVisible = !this.lineVisible;
            this.textVisible = false;
          }
        } else if (value === "text") {
          this.lineVisible = false;
          this.textVisible = true;
        } else {
          this.lineVisible = true;
          this.textVisible = false;
        }
      }

      this.$emit(
        "update:config",
        Object.assign({}, this.config, {
          [type]: value,
          mode: 1, // 点击之后，肯定是绘制模式
        })
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.list {
}
.tool-item {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: var(--al-component-bg-color);
  color: var(--al-text-color2);
  &.active {
    background: var(--al-main-color);
    color: var(--al-text-color2);
    font-size: 18px;
  }
}
.options {
  display: flex;
  .option-item {
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .point {
      display: inline-block;
      background: gray;
    }
    .color-round {
      width: 20px;
      height: 20px;
      border-radius: 20px;
    }
    &.small {
      .point {
        width: 2px;
        height: 2px;
        border-radius: 2px;
      }
    }
    &.middle {
      .point {
        width: 4px;
        height: 4px;
        border-radius: 4px;
      }
    }
    &.large {
      .point {
        width: 8px;
        height: 8px;
        border-radius: 8px;
      }
    }
    &.on {
      .point {
        background: red;
      }
      .color-round {
        box-shadow: red 0px 0px 10px;
      }
      .line {
        color: red;
      }
    }
  }
}
</style>
