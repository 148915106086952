<template>
  <el-main>
    <div class="container">
      <LeaveCard
        :leaveVisible="leaveVisible"
        :leaveCallBack="leaveCallBack"
        :endVisible="endVisible"
        :unPublishTrack="unPublishTrack"
        :emptyStore="emptyStore"
        :onRongLevae="onRongLevae"
        :unSubscribeTracks="unSubscribeTracks"
      />
      <div class="player">
        <div class="video-layout1" v-if="!videoLayoutType">
          <div
            id="videoMainContainer"
            :class="mediaStreamList.length > 1 ? 'video-main1' : 'video-main'"
            v-if="videoMainSource"
          >
            <!-- :style="
              mediaStreamList.length > 1
                ? 'height:calc(100% - 100px)'
                : 'height:100%;'
            " -->
            <sketch-board
              v-if="showMark"
              :background="markBackgroundImg"
              :baseWidth="drawBaseWidth"
              :baseHeight="drawBaseHeight"
              :userId="user.userId"
            />
            <playVideo
              :mediaStream="videoMainSource"
              :leng="mediaStreamList.length"
              :layout="videoLayoutType"
            ></playVideo>
          </div>
          <div class="vide-list" v-if="mediaStreamList.length > 1">
            <ul>
              <li
                v-for="(item, index) in mediaStreamList"
                :key="index"
                @click="handleVideoSource(item)"
              >
                <!-- <video src=""></video> -->
                <div class="video-box" v-if="item._id != videoMainSource._id">
                  <playVideo
                    :mediaStream="item"
                    :leng="mediaStreamList.length"
                    :layout="videoLayoutType"
                  ></playVideo>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- <sketch-board/> -->
        <div class="video-layout2" v-if="videoLayoutType">
          <!-- <div :class="markClass(cellCount)">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div> -->
          <div
            :class="cellClass(index2)"
            v-for="(item, index2) in mediaStreamList"
            :key="index2"
            @click="handleVideoSource(item)"
          >
            <playVideo
              :str1="index2 + 1"
              :mediaStream="item"
              :leng="mediaStreamList.length"
              :layout="videoLayoutType"
            ></playVideo>
          </div>
        </div>
      </div>
    </div>
    <!-- <sketch-board  /> -->
    <div class="footer">
      <div class="use" v-for="(item, index) in options" :key="index">
        <div class="change-use" v-for="(key, val) in item" :key="val">
          <div
            class="show-use"
            v-if="key.type"
            @click="handleUse(index, val, key.type)"
          >
            <div class="use-icon">
              <img :src="key.url" alt="" />
            </div>
            <span>{{ key.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </el-main>
</template>
<script>
import html2canvas from 'html2canvas'

import PlayVideo from './playVideo.vue'
import SketchBoard from '../SketchBoard'
import mike1 from '../../assets/img/meet_icon1.svg'
import mike2 from '../../assets/img/meet_icon1_1.svg'
import camera1 from '../../assets/img/meet_icon2.svg'
import camera2 from '../../assets/img/meet_icon2_2.svg'
import videoChange1 from '../../assets/img/meet_icon3.svg'
import videoChange2 from '../../assets/img/meet_icon3_1.svg'
import freezeScreen1 from '../../assets/img/meet_icon4.svg'
import freezeScreen2 from '../../assets/img/meet_icon4_1.svg'
import screen1 from '../../assets/img/meet_icon5.svg'
import screen2 from '../../assets/img/meet_icon5_1.svg'
import leave from '../../assets/img/meet_icon6.svg'
import leave1 from '../../assets/img/meet_icon6_1.svg'

import LeaveCard from '../leaveCard.vue'
import { isRoomOwner } from '../../api/index'
import { sleep } from '../../utils'
import Emitter from '../../thirdParty/Emitter'
import { Messages } from '../../thirdParty/RongClient'
import { mapState } from 'vuex'
export default {
  props: [
    'unPublishTrack',
    'emptyStore',
    'getRoomUserAndstreamType',
    'onRongLevae',
    'unSubscribeTracks',
  ],
  components: {
    PlayVideo: PlayVideo,
    LeaveCard: LeaveCard,
    SketchBoard,
  },
  data() {
    return {
      options: [
        [
          { name: '静音', url: mike1, type: true },
          {
            name: '解除',
            url: mike2,
            type: false,
          },
        ],
        [
          { name: '开启', url: camera2, type: true },
          { name: '关闭', url: camera1, type: false },
        ],
        [
          { name: '视频切换', url: videoChange2, type: true },
          { name: '视频切换', url: videoChange1, type: false },
        ],
        [
          { name: '冻屏', url: freezeScreen2, type: true },
          { name: '冻屏中', url: freezeScreen1, type: false },
        ],
        [
          { name: '共享', url: screen2, type: true },
          { name: '共享中', url: screen1, type: false },
        ],
        [
          { name: '离开', url: leave1, type: true },
          { name: '离开', url: leave, type: false },
        ],
      ],
      leaveVisible: false,
      endVisible: false,
      isCamera: false,
      isMicrophone: false,
      // changeIndex: 3,
      streamNum: 9,
      videoLayoutType: true, //true 为list布局 false 为九宫格布局
      // cellCount: 1, //默认从4开始
      showMark: false,
      curTrackId: '', // 当前用户的trackId
      showMark: false,
      markBackgroundImg: '',
      drawBaseWidth: 1024,
      drawBaseHeight: 2048,
    }
  },
  computed: {
    ...mapState(['rongClient', 'roomInfo']),
    user() {
      return this.$store.state.user
    },
    // 计算类样式属性
    cellClass() {
      return function (index) {
        switch (this.cellCount) {
          case 1:
            return ['player-w1']
          case 2:
            return ['player-w2']
          case 4:
            return ['player-w4']
          case 9:
            return ['player-9']
          default:
            break
        }
      }
    },
    markClass() {
      return function (index) {
        switch (this.cellCount) {
          case 1:
            return ['player-mark']
          case 2:
            return ['player-mark2']
          case 4:
            return ['player-mark4']
          case 9:
            return ['player-mark9']
          default:
            break
        }
      }
    },
    cellCount() {
      let index
      if (this.mediaStreamList.length == 0) {
        index = 1
      } else if (this.mediaStreamList.length == 1) {
        index = 1
      } else if (this.mediaStreamList.length == 2) {
        index = 2
      } else if (this.mediaStreamList.length <= 4) {
        index = 4
      } else if (this.mediaStreamList.length <= 9) {
        index = 9
      }

      return index
    },
    device() {
      return this.$store.state.rtcClient.getDevice()
    },
    rtcClient() {
      return this.$store.state.rtcClient.getRtcClient()
    },
    rtcRoom() {
      return this.$store.state.rtcClient.getRoom()
    },
    rcRtcCode() {
      return this.$store.state.rtcClient.getRCRTCCode()
    },
    rcLocalTrack() {
      return this.$store.state.rtcClient.getRCLocalTrack()
    },
    mediaStreamList() {
      let list = this.$store.state.mediaStreamList
      return list
    },
    videoMainSource() {
      let source = this.$store.state.mediaStreamMain
      return source
    },
  },
  watch: {
    videoMainSource(val, old) {
      console.log('watch', val, old)
      if (val == null) {
        this.videoLayoutType = true
        this.options[2][0].type = true
        this.options[2][1].type = false
      }
      //当主视频画面为屏幕共享时，切换视频显示模式
      if (val._tag == 'screenshare') {
        this.videoLayoutType = false
        this.options[2][0].type = false
        this.options[2][1].type = true
      }
    },
    mediaStreamList(val) {
      if (val.length == 0) {
        this.videoLayoutType = true
        this.options[2][0].type = true
        this.options[2][1].type = false
      }
    },
  },
  mounted() {
    Emitter.on('drawMark', this.handleExecMark)
    this.getDeviceList()
  },
  methods: {
    //  需要对照着data中的options来看 treen1 treen2 type 
    //  treen1 表示数组options的index 也就是第几个按钮
    //  treen2 表示数组options[treen1]的index,也就是说options中的
    //   第treen1个按钮数据是一个数组，treen2就是表示他是这个按钮的第几个状态
    //  type 如果是true就表示显示这个按钮 否则隐藏
    async handleUse(treen1, treen2, type) {
      let screentype = await this.streamTagScreenshare()
      this.options[treen1].forEach(async (item) => {
        if (this.options[treen1][treen2] == item) {
          if (treen1 == 0 && treen2 == 1 && !this.isMicrophone) {
            this.$message.error('无音频设备')
            return
          } else if (treen1 == 1 && treen2 == 0 && !this.isCamera) {
            this.$message.error('无摄像头设备')
            return
          } else if (
            treen1 == 2 &&
            treen2 == 0 &&
            this.mediaStreamList.length < 1
          ) {
            this.$message.error('暂无视频无法切换')
            return
          } else if (
            treen1 == 3 &&
            this.mediaStreamList.length < 1 &&
            !this.videoMainSource
          ) {
            this.$message.error('当前没有视频流')
            return
          } else if (treen1 == 3 && screentype) {
            this.$message.error('当前有屏幕分享，禁止冻屏')
            return
          } else  if (
            treen1 == 4 &&
            treen2 == 0 &&
            this.options[3][1].type == true
          ) {
            this.$message.error('冻屏中，禁止屏幕分享')
            return
          } else if (treen1 == 4 && treen2 == 0 && screentype) {
            this.$message.error('当前有屏幕分享，禁止再次分享')
            return
          } else {
            item.type = !type
          }

          if (treen1 == 0 && treen2 == 0) {
            //静音
            this.microphoneMute()
          } else if (treen1 == 0 && treen2 == 1) {
            //取消静音
            this.microphoneUnmute()
          } else if (treen1 == 1 && treen2 == 0) {
            //发布视频
            this.getMicroCamera()
          } else if (treen1 == 1 && treen2 == 1) {
            //取消视频
            this.unCamera()
          } else if (treen1 == 2 && treen2 == 0) {
            //视频切换 九宫格
            this.videoLayoutType = false
          } else if (treen1 == 2 && treen2 == 1) {
            //视频切换 列表
            this.videoLayoutType = true
          } else if (treen1 == 3 && treen2 == 0) {
            //冻屏
            this.handleFrozenScreen()
          } else if (treen1 == 3 && treen2 == 1) {
            //取消冻屏
            const message = new Messages.MarkMessage({ type: 'end' })
            this.rongClient.sendCustomMessage(this.roomInfo.roomId, message)
            this.showMark = false
          } else if (treen1 == 4 && treen2 == 0) {
            //屏幕共享
            item.type = true
            this.screen()
          } else if (treen1 == 4 && treen2 == 1) {
            //取消屏幕共享
            this.unScreen()
          } else if (treen1 == 5) {
            this.leaveAction()
            item.type = this.endVisible
          }
        } else {
          if (
            (treen1 == 1 && !this.isCamera) ||
            (treen1 == 0 && !this.isMicrophone) ||
            (treen1 == 2 && treen2 == 0 && this.mediaStreamList.length < 1) ||
            (treen1 == 4 && treen2 == 0) ||
            (treen1 == 4 && treen2 == 0 && this.options[3][1].type == true) ||
            (treen1 == 3 && screentype) ||
            (treen1 == 3 &&
              this.mediaStreamList.length < 1 &&
              !this.videoMainSource) ||
            (treen1 == 4 && treen2 == 0 && screentype)
          ) {
            return
          }
          item.type = type
        }
      })
    },
    /**
     * 当前视频中是否存在屏幕共享
     */
    streamTagScreenshare() {
      return new Promise(async (resolve, reject) => {
        let screen = this.$store.state.screenStream
        let tagType = false
        if (screen && screen._tag == 'screenshare') {
          tagType = true
        }
        if (
          this.videoMainSource &&
          this.videoMainSource._tag == 'screenshare'
        ) {
          tagType = true
        }

        this.forBack()
          .then((res) => {
            if (res) {
              tagType = res

              if (tagType) {
                resolve(tagType)
              } else {
                resolve(tagType)
              }
            } else {
              resolve(tagType)
            }
          })
          .catch((err) => {
            reject(false)
          })
      })
    },
    forBack() {
      return new Promise((resolve, reject) => {
        if (this.mediaStreamList.length < 1) {
          resolve(false)
        }
        for (let i = 0; i < this.mediaStreamList.length; i++) {
          if (this.mediaStreamList[i]._tag == 'screenshare') {
            resolve(true)
          } else {
            resolve(false)
          }
        }
      })
    },
    /**
     * 静音
     */
    microphoneMute() {
      let microphoneTrack = this.$store.state.microphoneStream
      console.log('音频资源静音')
      microphoneTrack.mute()
      this.getRoomUserAndstreamType()
       console.log(11111111112,'onTrackReady', microphoneTrack,false)
       this.$store.dispatch('syncUserStatus',{track:microphoneTrack,flag:false})
    },
    /**
     * 解除静音
     */
    microphoneUnmute() {
      let microphoneTrack = this.$store.state.microphoneStream
      console.log('音频资源解除静音')
      microphoneTrack.unmute()
      this.$store.dispatch('syncUserStatus',{track: microphoneTrack,flag:true})
       console.log(11111111113,'onTrackReady', microphoneTrack,true)
      this.getRoomUserAndstreamType()
    },
    /**
     * 获取摄像头设备列表，若为零不让发布视频
     */
    async getDeviceList() {
      const cameras = await this.device.getCameras()
      let microphones = await this.device.getMicrophones()
      // 拥有deviceId的才是有效的麦克风设备
      microphones = microphones.filter(m=>m.deviceId)
      if (microphones.length < 1) {
        this.isMicrophone = false
        this.options[0][0].type = false
        this.options[0][1].type = true
      } else {
        this.isMicrophone = true
      }
      if (cameras.length < 1) {
        this.isCamera = false
      } else {
        this.isCamera = true
      }
    },
    /**
     * 获取摄像头资源并发布
     */
    async getMicroCamera() {
      if (!this.isCamera) {
        this.$message.error('暂无摄像头设备')
      }
      /**
       * @description 仅当 `code === RCRTCCode.SUCCESS` 时 videoTrack 有值
       * videoTrack 为 RCCameraVideoTrack 类型实例
       * @param tag 资源标识，不传时默认为 RongCloudRTC，代表浏览器摄像头资源，
       * 也可传入其他包含 A-Z、a-z、0-9、+、=、- 的字符串，
       * @param ICameraVideoProfile 视频配置项，可选参数
       * @param ICameraVideoProfile.cameraId 指定摄像头设备 Id
       * @param ICameraVideoProfile.frameRate 指定视频帧率，默认为 RCFrameRate.FPS_15
       * @param ICameraVideoProfile.resolution 指定视频分辨率，默认为 RCResolution.W640_H480
       */
      const { code, track } = await this.rtcClient.createCameraVideoTrack()

      if (code == this.rcRtcCode.SUCCESS) {
        let arr = this.mediaStreamList
        arr.push(track)
        this.$store.commit('TYPE_MEDIASTREAMLIST', arr)

        this.$store.commit('TYPE_CAMERASTREAM', track)

        //视频切换主视频源
        this.$store.commit('TYPE_MEDIASTREAMMAIN', track)
        this.curTrackId = track.getStreamId()
        //发布资源
        this.publish(track)
      }
    },
    /**
     * 取消摄像头资源发布
     */
    unCamera() {
      let camera = this.$store.state.cameraStream
      this.unPublish(camera)
      this.emptyScreen(camera)
      this.$store.commit('TYPE_CAMERASTREAM', null)
    },
    /**
     * 屏幕共享
     */
    async screen() {
      const { code, track } = await this.rtcClient.createScreenVideoTrack()

      if (code == this.rcRtcCode.SUCCESS) {
        this.curTrackId = track.getStreamId()
        //屏幕共享资源添加至所有资源列表中
        this.options[4][0].type = false
        this.options[4][1].type = true
        //屏幕共享资源添加至屏幕共享资源文件中store
        this.$store.commit('TYPE_SCREENSTREAM', track)
        //发布资源
        this.publish(track)
      }
      //监听屏幕共享流结束事件
      track.on(this.rcLocalTrack.EVENT_LOCAL_TRACK_END, (track) => {
        // 取消发布屏幕共享流
        console.log(track, '取消发布屏幕共享流')
        this.options[4][0].type = true
        this.options[4][1].type = false
        this.unPublish(track)
        this.emptyScreen(track)
        this.$store.commit('TYPE_SCREENSTREAM', null)
        //关闭屏幕共享流时 置空资源
      })
      if (code !== this.rcRtcCode.SUCCESS) {
        console.warn('捕获屏幕共享流失败 ->', code)
        return
      }
    },

    /**
     * 取消屏幕共享
     */
    unScreen() {
      let screenStream = this.$store.state.screenStream
      if (screenStream) {
        screenStream.destroy()
        this.unPublish(screenStream)
        this.emptyScreen(screenStream)
        this.$store.commit('TYPE_SCREENSTREAM', null)
      }
    },
    /**
     * 置空屏幕共享存储数据与媒体列表中数据
     */
    emptyScreen(track) {
      let arr = this.mediaStreamList
      arr.forEach((item, index) => {
        if (item._id == track._id) {
          arr.splice(index, 1)
        }
      })
      this.$store.commit('TYPE_MEDIASTREAMLIST', arr)
    },
    /**
     * 点击切换视频布局及切换视频源 视频主体main源
     * @param {*} source 视频源
     */
    handleVideoSource(source) {
      this.videoLayoutType = false
      this.options[2][0].type = false
      this.options[2][1].type = true

      this.$store.commit('TYPE_MEDIASTREAMMAIN', source)
      this.curTrackId = source.getStreamId()
    },
    /**
     * 离开房间事件，是否为房主
     */
    leaveAction() {
      console.log('leaveVisible')

      let data = {
        token: this.$store.state.user.userId,
        roomId: this.$store.state.roomInfo.roomId,
        roomName: this.$store.state.roomInfo.roomName,
      }
      isRoomOwner(data)
        .then((res) => {
          console.log('是否为房主', res)
          this.leaveVisible = !this.leaveVisible

          this.endVisible = res.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    leaveCallBack(cb, endCb) {
      this.leaveVisible = cb
      this.endVisible = endCb
      this.options[5][0].type = true
      this.options[5][1].type = false
    },
    /**
     * 发布资源
     * @param {*} track 资源
     */
    async publish(track) {
      const { code } = await this.rtcRoom.publish([track])
      this.getRoomUserAndstreamType()
      this.$store.dispatch('syncUserStatus',{track,flag:true})
       console.log(11111111114,'onTrackReady', track,true)
      if (code !== this.rcRtcCode.SUCCESS) {
        console.warn('发布屏幕共享流失败 ->', code)
      }
    },
    /**
     * 取消发布资源
     * @param {*} track 资源
     */
    async unPublish(track) {
      const { code } = await this.rtcRoom.unpublish([track])
      this.getRoomUserAndstreamType()
      this.$store.dispatch('syncUserStatus',{track,flag:false})
       console.log(11111111115,'onTrackReady', track)
      if (code == this.rcRtcCode.SUCCESS) {
        console.log('取消发布成功')
        if (track._id == this.$store.state.mediaStreamMain._id) {
          this.$store.commit('TYPE_MEDIASTREAMMAIN', null)
        }
      }
      if (code !== this.rcRtcCode.SUCCESS) {
        console.log('取消发布失败:', code)
      }

      track.destroy()
      // 取消发布后，业务层若不再需要播放资源，可调 destroy 方法销毁资源
      // audioTrack.destroy()
      // videoTrack.destroy()
    },
    async handleFrozenScreen() {
      // 1 先切图
      let drawSource
      if (this.videoMainSource) {
        drawSource = this.videoMainSource
      } else {
        this.mediaStreamList.forEach((track) => {
          if (this.curTrackId === track.getStreamId()) {
            drawSource = track
          }
        })
      }

      let drawSourceStreamId = drawSource.getStreamId()
      const drawVideoElement = document.querySelector(
        `[data-track-id="${drawSourceStreamId}_1"]`
      )

      if (drawVideoElement) {
        const res = await html2canvas(drawVideoElement)
        this.markBackgroundImg = res.toDataURL('image/png')
      }
      // 2 设置主视频源
      this.handleVideoSource(drawSource)
      // 发送消息
      const message = new Messages.MarkMessage({
        type: 'start',
        trackId: drawSourceStreamId,
        windowHeight: this.drawBaseHeight,
        windowWidth: this.drawBaseWidth,
      })

      this.rongClient.sendCustomMessage(this.roomInfo.roomId, message)
      this.showMark = true
    },
    // 冻屏
    async handleExecMark(data) {
      console.log('画布事件', data)
      if (data.type === 'start') {
        console.log('进入start ', this.mediaStreamList)
        this.drawBaseHeight = data.windowHeight
        this.drawBaseWidth = data.windowWidth
        let drawSource
        // 把视频设置成主流
        this.mediaStreamList.forEach((track) => {
          if (data.trackId === track.getStreamId()) {
            drawSource = track
          }
        })

        let drawSourceStreamId = drawSource.getStreamId()
        // 截取屏幕图片
        const drawVideoElement = document.querySelector(
          `[data-track-id="${drawSourceStreamId}_1"]`
        )
        if (drawVideoElement) {
          const res = await html2canvas(drawVideoElement)
          this.markBackgroundImg = res.toDataURL('image/png')
        }
        // 2 设置主视频源
        this.handleVideoSource(drawSource)

        this.showMark = true
        this.options[3][1].type = true
        this.options[3][0].type = false
      } else if (data.type === 'end') {
        this.showMark = false
        this.options[3][0].type = true
        this.options[3][1].type = false
        //视频切换结合结束时状态改变
        // this.videoLayoutType = true
        // this.options[2][0].type = true
        // this.options[2][1].type = false
      } else if (data.type === 'draw') {
        this.drawBaseHeight = data.windowHeight
        this.drawBaseWidth = data.windowWidth
      }
    },
  },
  beforeDestroy() {
    Emitter.removeListener('drawMark', this.handleExecMark)
  },
}
</script>
<style lang="scss" scoped>
.el-main {
  padding: 0;
  // border: 1px solid #ccc;
  box-sizing: border-box;
  // margin-right: 20px;
  position: relative;
  // background: url('../../assets/img/mainBackground.png') no-repeat;
  // background: url(images/bg.png) no-repeat;
  background-size: 100% 100%;
  .container {
    height: calc(100% - 85px);
  }
  .footer {
    width: 100%;
    height: 85px;
    display: flex;
    position: relative;
    z-index: 999;
    justify-content: space-around;
    background: rgba(255, 255, 255, 0.05);
    // border-top: 1px solid #033261;
    padding-top: 12px;
    box-sizing: border-box;
    .show-use {
      display: flex;
      flex-direction: column;
      width: 63px;
      height: 60px;

      // opacity: 0.2;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 0.3s;
      .use-icon {
        width: 36px;
        height: 36px;
        // background: #0094f5;
        border-radius: 8px;
        // margin-bottom: 8px;
        img {
          width: 100%;
          height: auto;
          display: inline-block;
        }
      }

      span {
        // width: 24px;
        text-align: center;
        height: 12px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #acb9e7;
      }
    }
    .show-use:hover {
      background: var(--al-main-02-color);
    }
  }
}

.cell-tool {
  height: 40px;
  line-height: 30px;
  padding: 0 7px;
  position: absolute;
  top: 0;
  left: 0;
}
.player {
  height: 100%;
  background: black;
  // border-radius: 8px;
  .video-layout1 {
    height: 100%;
    position: relative;
    .vide-list {
      // width: 100%;
      // height: 120px;
      width: 210px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      ul {
        width: 210px;
        height: 100%;
        // display: flex;
        overflow-y: auto;
        overflow-x: hidden;
        li {
          width: 210px;

          .video-box {
            width: 210px;
            height: auto;
            aspect-ratio: 16/9;
            margin-right: 10px;
          }
        }
      }
    }
    .video-main {
      // width: 100%;
      height: 100%;
      // aspect-ratio: 16/9;
      position: relative;
      overflow: hidden;
      margin: 0 auto;
    }
    .video-main1 {
      width: calc(100% - 210px);
      // height: calc(100% - 120px);
      height: 100%;
      // aspect-ratio: 16/9;
      position: relative;
      overflow: hidden;
      // margin: 0 auto;
    }
  }
  .video-layout2 {
    height: 100%;
    position: relative;
  }
}
.player-mark {
  display: none;
}
.player-mark2 {
  width: 100%;
  height: 100%;
  // background: red;
  position: absolute;
  top: 0;
  left: 0;
  :nth-child(1) {
    display: inline-block;
    width: 1px;
    height: 100%;
    background: var(--al-text-03-color1);
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 10;
  }
}
.player-mark4 {
  width: 100%;
  height: 100%;
  // background: red;
  position: absolute;
  top: 0;
  left: 0;
  :nth-child(1) {
    display: inline-block;
    width: 1px;
    height: 100%;
    background: var(--al-text-03-color1);
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 10;
  }
  :nth-child(2) {
    display: inline-block;
    width: 100%;
    height: 1px;
    background: var(--al-text-03-color1);
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 10;
  }
}
.player-mark9 {
  width: 100%;
  height: 100%;
  // background: red;
  position: absolute;
  top: 0;
  left: 0;
  :nth-child(1) {
    display: inline-block;
    width: 1px;
    height: 100%;
    background: var(--al-text-03-color1);
    position: absolute;
    top: 0;
    left: 33.33%;
    z-index: 10;
  }
  :nth-child(2) {
    display: inline-block;
    width: 1px;
    height: 100%;
    background: var(--al-text-03-color1);
    position: absolute;
    top: 0;
    left: 66.66%;
    z-index: 10;
  }
  :nth-child(3) {
    display: inline-block;
    width: 100%;
    height: 1px;
    background: var(--al-text-03-color1);
    position: absolute;
    top: 33.33%;
    left: 0;
    z-index: 10;
  }
  :nth-child(4) {
    display: inline-block;
    width: 100%;
    height: 1px;
    background: var(--al-text-03-color1);
    position: absolute;
    top: 66.66%;
    left: 0;
    z-index: 10;
  }
}
.player-w1 {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  // border: 1px solid white;
}

.player-mark9 {
}
.player-w2 {
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  float: left;
  // border: 1px solid var(--al-text-03-color1);
}
.player-w4 {
  width: 50%;
  height: 50% !important;
  box-sizing: border-box; /**可有可无 */
  float: left;
  // border: 1px solid var(--al-text-03-color1);
}
.player-9 {
  width: 33.33%;
  height: 33.33% !important;
  box-sizing: border-box;
  float: left;
  // border: 1px solid var(--al-text-03-color1);
}
</style>
