<template>
  <div class="container">
    <al-nav>
      <template slot="left">
        <div class="input-box">
          <input type="text" placeholder="请输入选猪ID" v-model="roomId" />
          <div class="input-btn" @click="handleJoinRoom({ roomId })">加入</div>
        </div>
        <div class="btn" @click="handleCreateRoom">发起选猪</div>
        <div class="btn" @click="handleJoinCurRoom" v-if="isJoinBut">
          当前选猪
        </div>
      </template>
    </al-nav>
    <div class="card-list" v-if="list.length">
      <record-card
        v-for="item in list"
        :key="item.id"
        :data="item"
        mode="home"
        @join="handleJoinRoom(item)"
      />
    </div>
    <div class="card-list" v-else>
      <span>暂无会议</span>
    </div>

    <div class="pagination">
      <el-pagination
        :pager-count="11"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout=" prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      custom-class="create-room-dialog"
      title="选猪主题"
      :visible.sync="dialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-input
        v-model="roomName"
        class="room-name-input"
        placeholder="请输入选猪主题"
        maxlength="20"
      />
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-btn cancel-btn" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button class="dialog-btn" type="primary" @click="doCreateRoom"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AlNav from '../../components/Nav.vue'
import RecordCard from '../../ui-components/RecordCard.vue'
import { getListInProgress, getUserUnfinishedRoomInfo } from '../../api'
import lodash from 'lodash'
export default {
  components: {
    AlNav,
    RecordCard,
  },
  data() {
    return {
      roomId: '',
      list: [],
      total: 0,
      pageNum: 1,
      pageSize: 8,
      dialogVisible: false,
      roomName: '',
      isJoinBut: false,
      fnTimeout: null,
      intType: 0,
    }
  },
  computed: {
    ...mapState(['roomInfo']),
    user() {
      return this.$store.state.user
    },
    isTokenBackType() {
      return this.$store.state.isTokenBackType
    },
    mediaToken() {
      return this.$store.state.mediaToken
    },
  },
  watch: {
    user(val) {
      console.log(val)
    },
    isTokenBackType(val, old) {
      if (val) {
        this.onInit()
      }
    },
    mediaToken(val) {
      if (val) {
        clearTimeout(this.fnTimeout)
        this.onInit()
      }
    },
  },
  mounted() {
    if (this.$store.state.token && this.mediaToken) {
      this.onInit()
    }
  },
  methods: {
    ...mapActions(['createRoom', 'joinRoom', 'init']),
    onInit() {
      this.intType = this.intType + 1
      Promise.all([this.getList(), this.getRoomBtn()]).then((result) => {
        this.fnTimeout = setTimeout(() => {
          this.onInit()
        }, 3000)
      })
    },
    // 判断是否展示“当前选猪”按钮
    async getRoomBtn() {
      let data = {
        token: this.$store.state.token,
      }
      let res = await this.init(data)
      if (res.success) {
        if (res.data.haveNotFinishedRoom) {
          this.isJoinBut = true
        }
      } else {
        this.isJoinBut = false
      }
    },
    async getList() {
      const res = await getListInProgress({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      })
      this.list = res.data.list
      this.total = res.data.total
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    async handleJoinRoom(row) {
      let data = {
        token: this.$store.state.token,
      }
      let res = await this.init(data)

      if (res.success) {
        if (res.data.haveNotFinishedRoom) {
          if (res.data.notFinishedRoomId == row.roomId) {
            this.joinAssist(row)
          } else {
            this.$confirm('是否进入新的会议？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(async () => {
                this.joinAssist(row)
              })
              .catch(() => {})
          }
        } else {
          this.joinAssist(row)
        }
      }
    },
    async joinAssist(row) {
      let joinRes = await this.joinRoom({
        roomId: row.roomId,
      })
      if (joinRes) {
        this.$router.push('/index/assist')
      }
    },
    handleCreateRoom() {
      const userName = this.$store.state.user.userName
      if (userName) {
        this.roomName = `${userName}发起的选猪`
      }
      this.dialogVisible = true
    },
    handleJoinCurRoom() {
      let data = {
        token: this.$store.state.token,
      }
      getUserUnfinishedRoomInfo(data).then(async (result) => {
        if (result.success) {
          if (result.data.haveNotFinishedRoom) {
            const res = await this.joinRoom({
              roomId: result.data.notFinishedRoomId,
            })
            if (res) {
              this.$router.push('/index/assist')
            }
          }else{
            this.isJoinBut = false
            this.$message.error('会议已结束')
          }
        }
      })
    },
    async doCreateRoom() {
      const result = await this.createRoom({
        roomName: this.roomName,
      })
      if (result.success) {
        this.roomName = ''
        this.dialogVisible = false
        this.$router.push('/index/assist')
      }
    },
  },
  destroyed() {
    console.log('销毁')
    clearTimeout(this.fnTimeout)
  },
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0 20px;
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--al-border-color);
    // border: 1px solid var(--al-secondary-color);
    width: 87px;
    height: 30px;
    border-radius: 5px;
    // color: var(--al-text-color1);
    color: var(--al-secondary-color);
    font-size: 12px;
    margin-right: 10px;
    cursor: pointer;
  }
  .input-box {
    width: 190px;
    height: 30px;
    display: flex;
    margin-right: 26px;
    border: 1px solid var(--al-border-color);
    border-radius: 5px;
    overflow: hidden;
    input {
      padding-left: 13px;
      height: 100%;
      flex-grow: 2;
      width: 20px;
      background: transparent;
      border: none;
      color: var(--al-text-color1);
    }
    .input-btn {
      cursor: pointer;
      height: 100%;
      width: 53px;
      background: var(--al-main-color);
      border-radius: 0px 5px 5px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      // color:var(--al-text-color1);;
      color: #fff;
    }
  }
  .card-list {
    display: flex;
    flex-flow: row wrap;
    height: 540px;
    span {
      display: block;
      font-size: 30px;
      color: var(--al-text-03-color1);
      user-select: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
  }
}
</style>
<style lang="scss">
.el-message-box__title {
  font-size: 15px;
}
.pagination {
  .el-pagination {
    color: var(--al-text-03-color1);
  }
  .el-pagination button {
    background: transparent;
  }
  .el-pager li {
    background: transparent;
  }
  .el-pager li.active {
    color: var(--al-text-color1);
  }
}

.create-room-dialog {
  width: 417px;
  height: 241px;
  background: var(--al-dialog-bg-color);
  border: 1px solid var(--al-border-color);
  border-radius: 3px;
  .el-dialog__header {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-bottom: 1px solid var(--al-border-color);
  }
  .el-dialog__title {
    font-size: 18px;
    color: var(--al-text-color1);
  }
  .el-dialog__body {
    padding: 25px 40px;
  }
  .room-name-input .el-input__inner {
    background: var(--al-component-bg-03-color);
    border: 1px solid var(--al-border-color);
    border-radius: 3px;
    color: var(--al-text-color1);
  }
  .el-input__inner::-webkit-input-placeholder {
    color: var(--al-text-color1);
  }
  .el-dialog__footer {
    padding: 0;
  }
  .dialog-footer {
    padding: 20px 40px 0 40px;
    display: flex;
    justify-content: space-between;
  }
  .dialog-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 159px;
    height: 40px;
    background: var(--al-main-color);
    border-radius: 5px;
    font-size: 14px;
    border: none;
    color: var(--al-text-color2);
  }
  .cancel-btn {
    background: var(--al-main-01-color);
  }
}
</style>
