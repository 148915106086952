<template>
  <div class="pid-detail-container">
    <div class="img-box">
      <div class="img" :style="data.photo?{
         background: `url(${data.photo}) no-repeat center center / contain`
      }:null"></div>
      <div class="score">{{ data.score }}</div>
      <div class="score-label">育种评分</div>
    </div>

    <div class="info-box">
      <div class="info-item">
        耳号:<span class="value">{{ data.id }}</span>
      </div>
      <div class="info-item">
        性别:<span class="value">{{ data.sex }}</span>
      </div>
      <div class="info-item">
        栋舍:<span class="value">{{ data.seedFieldName }}</span>
      </div>
      <div class="info-item">
        栏位:<span class="value">{{ data.fieldNumber }}</span>
      </div>
      <div class="info-item">
        品系:<span class="value">{{ data.varietiesName }}</span>
      </div>
      <div class="info-item">
        总乳头数:<span class="value">{{ data.totalNippleNum }}</span>
      </div>
      <!-- <div class="info-item">同窝仔猪数:<span class="value">无数据</span></div> -->
      <div class="info-item">
        背膘厚度:<span class="value" v-if="data.fatNumber"
          >{{ data.fatNumber }}mm</span
        >
      </div>
      <div class="info-item">
        出生重:<span class="value" v-if="data.baseBirthWeight"
          >{{ data.baseBirthWeight }}kg</span
        >
      </div>
      <!-- <div class="info-item">出生胎次:<span class="value">无数据</span></div>
      <div class="info-item">出生日期:<span class="value">无数据</span></div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: ['data'],
}
</script>

<style lang="scss" scoped>
.pid-detail-container {
  display: flex;
  // padding-top: 71px;
  justify-content: center;
  .img-box {
    width: 295px;
  }
  .img {
    width: 100%;
    height: 220px;
    background: url('../assets/img/pig.png') no-repeat center center / contain;
  }
  .score {
    margin-top: 25px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    color: var(--al-text-color1);
    text-align: center;
  }
  .score-label {
    margin-top: 11px;
    font-size: 12px;
    line-height: 12px;
    color: var(--al-text-color1);
    opacity: 0.5;
    text-align: center;
  }
  .info-box {
    padding-left: 97px;
    width: 140px;
  }
  .info-item {
    font-size: 12px;
    color: var(--al-text-color1);
    line-height: 32px;
    .value {
      color: var(--al-main-color);
      padding-left: 5px;
    }
  }
}
</style>
