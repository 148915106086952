<template>
  <div class="wrap">
    <ul class="user-list">
      <li v-for="(item, index) in users" :key="index">
        <div class="left">
          <svg-icon icon-class="userAvatar" class="svg"></svg-icon>
          <span>{{ userNameMap[item.userId] }}</span>
        </div>
        <div class="right">
          <!-- <div >
						<img :src="item.video ? cameraBg : camera" alt="" class="video" />
						
					</div> -->
          <div class="stream-box" >
            <!-- <img :src="item.audioType ? audioBg : audio" alt="" class="audio" /> -->
            <svg-icon
              :icon-class="item.audioType ? 'audio-color' : 'meet_icon1_1'"
              class="svgbg"
            ></svg-icon>
          </div>
          <div class="stream-box" >
            <!-- <img
              :src="item.videoType ? cameraBg : camera"
              alt=""
              class="video"
            /> -->
            <svg-icon
              :icon-class="
                item.videoType ? 'meet_camera_icon1' : 'meet_camera_icon2'
              "
              :class="item.videoType ? 'svgbg' : 'svg'"
            ></svg-icon>
          </div>
          <div class="stream-box" v-if="item.screen">
            <svg-icon icon-class="screen" class="svgbg"></svg-icon>
          </div>
        </div>
      </li>
      <!-- <img :src="cs1" alt="" /> -->
    </ul>
  </div>
</template>
<script>
// import avatar from '../../assets/img/avatar.jpg'
import svgIcon from '../svgIcon.vue'
// import avatar from '../../assets/img/userAvatar.svg'
// import cameraColor from '../../assets/img/meet_camera_icon2.svg'
// import camera from '../../assets/img/meet_camera_icon1.svg'
// import audioColor from '../../assets/img/audio-color.svg'
// import audio from '../../assets/img/meet_icon1_1.svg'
// import cs1 from '../../assets/img/meet_audio_icon1.svg'
// import screen from '../../assets/img/screen.svg'
export default {
  components: {
    svgIcon,
  },
  data() {
    return {
      // cs1: cs1,
      // avatar: avatar,
      // cameraBg: cameraColor,
      // camera: camera,
      // audioBg: audioColor,
      // audio: audio,
      // screen: screen,
      // userList: [
      //   {
      //     id: 1,
      //     name: '赵六',
      //     camera: true,
      //     audio: true,
      //   },
      //   {
      //     id: 2,
      //     name: '张三',
      //     camera: false,
      //     audio: true,
      //   },
      //   {
      //     id: 3,
      //     name: '李四',
      //     camera: false,
      //     audio: false,
      //   },
      //   {
      //     id: 4,
      //     name: '王五',
      //     camera: true,
      //     audio: false,
      //   },
      // ],
    }
  },
  computed: {
    curUser(){
      return this.$store.state.user
    },
    userList() {
      return this.$store.state.memberList
    },
     users() {
      return this.$store.state.users
    },
    userNameMap() {
      const contacts =  this.$store.state.contacts
      const nameMap = {
        [this.curUser.userId]:this.curUser.userName
      }
      contacts.forEach(item=>{
        nameMap[item.userId] = item.username
      })

      console.log('=========',nameMap)
      return nameMap
    },
  },
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
}
.user-list {
  height: 100%;
  overflow-y: auto;
  li {
    height: 40px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .svg {
        width: 15px;
        height: 15px;
        display: inline-block;
        color: var(--al-text-color1);
      }
      span {
        color: var(--al-text-color1);
        font-size: 12px;
        margin-left: 9px;
      }
    }
    .right {
      display: flex;
      .stream-box {
        width: 16px;
        height: 16px;
        .svgbg {
          color: var(--al-tips-color);
        }
        .svg {
          color: white;
        }
      }
      :nth-child(2) {
        margin-left: 20px;
      }
      :nth-child(3) {
        margin-left: 20px;
      }
      img {
        width: 16px;
        height: 16px;
        display: inline-block;
      }
    }
  }
}
</style>
