<template>
  <div class="nav-box">
    <div class="left-box">
      <slot name="left"></slot>
    </div>
    <div class="left-box">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNav: "",
    };
  },
  mounted() {},
  methods: {
    toHome() {
      this.activeNav = "home";
      this.$router.push("/index");
    },
    toHistory() {
      this.activeNav = "history";
      this.$router.push("/index/history");
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-box {
  margin-top: 20px;
  display: flex;
  height: 47px;
  margin-bottom: 11px;
  justify-content: space-between;
  align-items: center;
}
.left-box {
  display: flex;
  align-items: center;
}
.right-box {
  display: flex;
  align-items: center;

}
.center-box {
  display: flex;
  width: 192px;
  justify-content: space-between;
}
</style>