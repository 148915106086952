<template>
  <div class="wrap">
    <div class="container" id="container-chatList">
      <el-scrollbar ref="scrollbar">
        <ul
          class="chatList"
          ref="chatList"
          :style="{ height: scrollHeight + 'px' }"
        >
          <li v-for="(item, index) in msgList" :key="index">
            <div class="right-msggroup" v-if="item.position">
              <div class="right-title">
                <span class="right-msgname">{{
                  item.data.content.user.name
                }}</span>
                <div class="right-avater">
                  <svg-icon icon-class="userAvatar" class="svg"></svg-icon>
                </div>
              </div>

              <div class="right-msgcontainer">
                <div
                  :class="
                    item.type == 'img'
                      ? 'right-img right-msgcont'
                      : 'right-msgcont'
                  "
                >
                  <!-- TextMessage -->
                  <p v-if="item.type == 'text'">
                    {{ item.data.content.content }}
                  </p>
                  <img
                    v-if="item.type == 'img'"
                    @click="onPreview(item.data.content.imageUri)"
                    :src="item.data.content.imageUri"
                    alt=""
                  />
                  <a
                    :href="item.data.content.fileUrl"
                    class="left-file-box"
                    v-if="item.type == 'file'"
                  >
                    <!-- <img
                      v-if="item.data.content.type == 'txt'"
                      :src="txtIcon"
                      alt=""
                      class="icon"
                    /> -->
                    <!-- <img
                      v-if="item.data.content.type == 'docx'"
                      :src="wordIcon"
                      alt=""
                      class="icon"
                    /> -->
                    <!-- {{ item.data.content.type }} -->
                    <!-- <img
                      v-if="item.data.content.type == 'xlsx'"
                      :src="excelIcon"
                      alt=""
                      class="icon"
                    /> -->
                    <!-- <img
                      v-if="item.data.content.type == 'pdf'"
                      :src="pdfIcon"
                      alt=""
                      class="icon"
                    /> -->
                    <span>{{ item.data.content.name }}</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="left-msggroup" v-if="!item.position">
              <div class="left-title">
                <div class="left-avater">
                  <svg-icon icon-class="userAvatar" class="svg"></svg-icon>
                </div>
                <span class="left-msgname">{{
                  item.data.content.user.name
                }}</span>
              </div>

              <div class="left-msgcontainer">
                <div
                  :class="
                    item.type == 'img'
                      ? 'left-img left-msgcont'
                      : 'left-msgcont'
                  "
                >
                  <p v-if="item.type == 'text'">
                    {{ item.data.content.content }}
                  </p>
                  <img
                    v-if="item.type == 'img'"
                    @click="onPreview(item.data.content.imageUri)"
                    :src="item.data.content.imageUri"
                    alt=""
                  />
                  <a
                    :href="item.data.content.fileUrl"
                    class="left-file-box"
                    v-if="item.type == 'file'"
                  >
                    <!-- <img
                      v-if="item.data.content.type == 'txt'"
                      :src="txtIcon"
                      alt=""
                      class="icon"
                    /> -->
                    <!-- <img
                      v-if="item.data.content.type == 'docx'"
                      :src="wordIcon"
                      alt=""
                      class="icon"
                    /> -->
                    <!-- {{ item.data.content.type }} -->
                    <!-- <img
                      v-if="item.data.content.type == 'xlsx'"
                      :src="excelIcon"
                      alt=""
                      class="icon"
                    /> -->
                    <!-- <img
                      v-if="item.data.content.type == 'pdf'"
                      :src="pdfIcon"
                      alt=""
                      class="icon"
                    /> -->
                    <span>{{ item.data.content.name }}</span>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <el-image-viewer
            v-if="showPreview"
            :on-close="closePreview"
            :url-list="[previewSrc]"
          />
        </ul>
      </el-scrollbar>
    </div>
    <div class="box">
      <!-- <div class="box-edit" contentEditable="true"  ></div> -->
      <!-- class="textareaSty" -->
      <el-input
        @paste.native="onPaste($event)"
        v-model="message"
        placeholder="此处为聊天框，可支持文字，图片，文件传送"
        clear
        @keyup.enter.native="postMsg"
      ></el-input>
      <!-- <div
        ref="textarea"
        contenteditable="true"
        class="textareaSty"
        id="pasteContent"
        @paste.prevent="onPaste"
      >
        
      </div> -->
      <!-- <span class="send-btn" @click="postMsg">发送(s)</span> -->
    </div>
  </div>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import onPaste from '../../utils/onPaste'
import svgIcon from '../svgIcon.vue'

import avatar from '../../assets/img/avatar.jpg'
import txtIcon from '../../assets/img/txt.png'
import wordIcon from '../../assets/img/word.png'
import excelIcon from '../../assets/img/excel.png'
import pdfIcon from '../../assets/img/pdf.jpg'
export default {
  components: { ElImageViewer, svgIcon },
  data() {
    return {
      avatar: avatar,
      txtIcon: txtIcon,
      wordIcon: wordIcon,
      excelIcon: excelIcon,
      pdfIcon: pdfIcon,
      scrollHeight: 0,
      inputMsg: '',
      list: [],
      message: '',
      canUpload: false,
      previewSrc: '',
      showPreview: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    targetId() {
      return this.$store.state.roomInfo.roomId
    },
    msgList() {
      return this.$store.state.messageList
    },
    rongClient() {
      return this.$store.state.rongClient
    },
    imLib() {
      return this.$store.state.rongClient.getImLib()
    },
  },
  watch: {
    msgList(val) {
      if (val) {
        this.onScrollBtm()
      }
    },
  },
  mounted() {
    this.scrollHeight =
      document.getElementById('container-chatList').clientHeight
  },
  methods: {
    bindEnter() {},
    onScrollBtm() {
      this.$nextTick(() => {
        console.log(
          this.$refs.chatList.scrollTop,
          this.$refs.chatList.scrollHeight
        )
        setTimeout(() => {
          this.$refs.chatList.scrollTop = this.$refs.chatList.scrollHeight
        }, 500)
      })
    },
    /**
     * 发送消息
     */
    async postMsg() {
      if (!this.message) {
        return
      }
      const result = await this.$store.state.rongClient.sendMessage(
        this.targetId,
        {
          content: this.message,
          user: { id: this.user.userId, name: this.user.userName },
        },
        'text'
      )

      if (result) {
        console.log('发送成功')
        this.setMsg({
          type: 'text',
          content: this.message,
          user: { id: this.user.userId, name: this.user.userName },
        })
        this.message = ''
      } else {
        this.message = ''
        this.$message.error('消息发送失败')
      }
    },
    onPreview(src) {
      this.previewSrc = src
      this.showPreview = true
    },
    closePreview() {
      this.showPreview = false
    },
    // DivEditable.vue
    async onPaste(event) {
      const result = await onPaste(event)
      console.log('处理后的粘贴数据', result)

      console.log(result)
      if (result.type == 'file') {
        this.sendFile(result.data)
      } else if (result.type == 'img') {
        this.sendImg(result.data)
      }
      // const imgRegx =
      //   /^data:image|data:png|data:jpg|data:jpeg|data:gif|data:svg|bmp|tif/ // 支持的图片格式
      // console.log(imgRegx.test(result.compressedDataUrl))
      // if (imgRegx.test(result.compressedDataUrl)) {
      //   // document.execCommand('insertImage', false, result.compressedDataUrl);
      //   const sel = window.getSelection() // 获取当前光标位置
      //   if (sel && sel.rangeCount === 1 && sel.isCollapsed) {
      //     const range = sel.getRangeAt(0)
      //     const img = new Image()
      //     img.src = result.compressedDataUrl // 使用压缩后的图片
      //     range.insertNode(img)
      //     range.collapse(false)
      //     sel.removeAllRanges()
      //     sel.addRange(range)
      //   }
      // }
    },

    /**
     * 发送文件
     */
    sendFile(file) {
      const conversation = {
        conversationType: this.imLib.ConversationType.CHATROOM,
        targetId: this.targetId,
      }

      const msgBody = {
        file, // 待上传文件
        user: { id: this.user.userId, name: this.user.userName }, // 消息中携带的用户信息，非必填
        extra: '', // 消息中携带的透传信息，非必填
      }

      const hooks = {
        onProgress(progress) {
          console.log(progress)
        }, // 上传进度监听，可选
        onComplete(fileInfo) {
          // 上传完成时的回调钩子，可选
          console.log(fileInfo.url) // 文件存储地址
          // 如果需要构建自定义消息，return new ABCMesssage('')
          // ABCMesssage 定义通过自定义消息实现 `const ABCMesssage = RongIMLib.registerMessageType(...)`
          // 无 return 返回值的情况下，SDK 默认发送 FileMessage
        },
      }

      const options = {
        contentDisposition: 'attachment', // 文件链接在浏览器中展示形式（仅 aws、stc 上传有效） 'inline': 在浏览器中预览， 'attachment': 直接下载。如果不传，html 类型文件会预览，其他类型为直接下载
        // ... 其他配置项，可选
      }

      this.imLib
        .sendFileMessage(conversation, msgBody, hooks, options)
        .then(({ code, data }) => {
          if (code === 0) {
            console.log('消息文件发送成功', data)
            // 发送成功
            let msg = data
            this.setMsg({
              type: 'file',
              fileUrl: msg.content.fileUrl,
              name: msg.content.name,
              fileType: msg.content.type,
              size: msg.content.size,
              user: msg.content.user,
            })
          }
        })
    },
    sendImg(file) {
      const conversation = {
        conversationType: this.imLib.ConversationType.CHATROOM,
        targetId: this.targetId,
      }
      const msgBody = {
        file, // 待上传文件
        user: { id: this.user.userId, name: this.user.userName }, // 消息中携带的用户信息，非必填
        extra: '', // 消息中携带的透传信息，非必填
      }
      const hooks = {
        onProgress(progress) {}, // 上传进度监听，可选
        onComplete(fileInfo) {
          // 上传完成时的回调钩子，可选
          console.log(fileInfo.url) // 文件存储地址
          // 如果需要构建自定义消息，return new ABCMesssage('')
          // ABCMesssage 定义通过自定义消息实现 `const ABCMesssage = RongIMLib.registerMessageType(...)`
          // 无 return 返回值的情况下，SDK 默认发送 FileMessage
        },
      }
      const options = {
        contentDisposition: 'inline', // 'inline' | 'attachment' , 使用 aws 上传时返回链接在浏览器中的展示形式
        // ... 其他配置项，可选
      }

      this.imLib
        .sendImageMessage(conversation, msgBody, hooks, options)
        .then(({ code, data }) => {
          if (code === 0) {
            console.log('消息图片发送成功', data)
            // 发送成功
            let msg = data
            this.setMsg({
              type: 'img',
              imageUri: msg.content.imageUri,
              user: msg.content.user,
            })
          }
        })
    },
    /**
     * 自发消息体存储
     * @param {*} param
     */
    setMsg(param) {
      let { type, content, imageUri, fileUrl, name, fileType, size, user } =
        param
      let suffixType
      if (type == 'file') {
        var suffix = name.substring(name.lastIndexOf('.') + 1)
        console.log(suffix)
        if (suffix == 'docx') {
          suffixType = 'docx'
        } else if (suffix == 'xlsx') {
          suffixType = 'xlsx'
        } else if (suffix == 'pdf') {
          suffixType = 'pdf'
        } else if (suffix == 'txt') {
          suffixType = 'txt'
        }
      }
      let data = {
        data: {
          content: {
            content,
            imageUri,
            fileUrl,
            name,
            size,
            type: suffixType,
            user: {
              id: user.id,
              name: user.name,
              portrait: '',
            },
          },
        },
        type,
        position: true,
      }
      console.log(data)
      let list = this.$store.state.messageList
      list.push(data)
      this.$store.commit('TYPE_MESSAGELIST', list)
    },
    pasteImg(event) {
      //粘贴上传图片
      let txt = event.clipboardData.getData('Text')
      // let imgType = event.clipboardData.getData('Image')
      // let fileType = event.clipboardData.getData()
      console.log(event, txt)
      if (typeof txt == 'string' && txt !== '') {
        //检测到是文字，过滤
        return
      }
      let file = null
      const items = (event.clipboardData || window.clipboardData).items
      console.log(items)
      if (items.length) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            file = items[i].getAsFile()
            // this.handleChange(file)
            console.log(file)
            if (!this.canUpload) {
              this.canUpload = !this.canUpload
            }
            break
          }
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped src="./chat.scss"></style>
<style lang="scss" scoped>
/deep/.el-input {
  // 输入框高度
  height: 36px;
  // 边框圆角
  border-radius: 18px;
}
/deep/.el-input__inner {
  padding: 0;
  padding-left: 4px;
  height: 36px;
  // background: var(--al-component-bg-01-color);
  background: rgba(254, 254, 254, 0.05);
  // 设置字号
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  // 设置输入字体的颜色
  color: var(--al-text-color1);
  border: 0;
  border-radius: 18px;
}
/deep/.el-input__inner::-webkit-input-placeholder {
  /* WebKit browsers，webkit内核浏览器 */
  color: rgba(172, 185, 231, 0.3);
  font-size: 10px;
}
</style>
