<template>
  <el-dialog
    title="猪"
    :visible.sync="visible"
    width="600"
    :close-on-click-modal="false"
    custom-class="select-pig-modal"
  >
    <i class="el-icon-circle-close close-icon" @click="handleClose"></i>
    <div
      class="arrow-btn arrow-left"
      @click="handlePrev"
      v-if="pigs.length > 1"
    ></div>
    <div
      class="arrow-btn arrow-right"
      @click="handleNext"
      v-if="pigs.length > 1"
    ></div>
    <el-carousel
      trigger="click"
      arrow="never"
      :autoplay="false"
      :loop="false"
      indicator-position="none"
      ref="carouselRef"
    >
      <el-carousel-item
        v-for="item in pigs"
        :key="item.id"
        class="carousel-item"
      >
        <pig-card :data="item" />
        <div class="select-btns">
          <div
            class="select-btn"
            @click="handleChoose(item, true)"
          >
            选中
          </div>
          <div
            class="select-btn give-up"
            @click="handleChoose(item, false)"
          >
            放弃
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </el-dialog>
</template>

<script>
import lodash from 'lodash'
import { Messages } from '../../thirdParty/RongClient'
import emitter from '../../thirdParty/Emitter'
import PigCard from '../PigCard.vue'
import { rejectPig, choosePig } from '../../api'
export default {
  components: {
    PigCard,
  },
  props: ['visible', 'pigs', 'type', 'index'],
  data() {
    return {}
  },
  watch: {
    index() {
      if (this.$refs.carouselRef) {
        this.$refs.carouselRef.setActiveItem(this.index || 0)
      }
    },
  },
  created() {},
  methods: {
    handlePrev() {
      this.$refs.carouselRef.prev()
    },
    handleNext() {
      this.$refs.carouselRef.next()
    },
    async handleChoose(item, selected) {
      let roomId = this.$store.state.roomInfo.roomId
      let rongClient = this.$store.state.rongClient

      let res
      // 通知后端
      if (selected) {
        res = await choosePig({
          pigId: item.id,
          roomId,
        })
      } else {
        res = await rejectPig({
          pigId: item.id,
          roomId,
        })
      }

      if (res.success) {
        this.$emit('refreshPigList')
        emitter.emit('refreshPigList')
        // 通知别人
        const message = new Messages.PigMessage({
          type: 'select',
          sendTime: Date.now(),
          data: {
            pigId: item.id,
            selected: selected,
          },
        })
        rongClient.sendCustomMessage(roomId, message)

        // todo 刷新数据
      }
    },
    handleClose() {
      this.$emit('close')
    },
  },

  beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.arrow-btn {
  position: absolute;
  top: 50%;
  width: 22px;
  height: 25px;
  background: red;
  transform: translateY(-50%);
  cursor: pointer;
  &.arrow-left {
    left: 40px;
    background: url('../../assets/img/piginfo_botton_left.png') no-repeat center
      center/contain;
  }
  &.arrow-right {
    right: 40px;
    background: url('../../assets/img/piginfo_botton_right.png') no-repeat
      center center/contain;
  }
}
.close-icon {
  font-size: 22px;
  color: var(--al-text-color2);
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}
.select-btns {
  position: absolute;
  bottom: 50px;
  left: 100px;
  display: flex;
  width: 280px;
  justify-content: center;
}
.select-btn {
  width: 120px;
  height: 40px;
  border: 1px solid var(--al-border-color);
  border-radius: 3px;
  color: var(--al-text-color2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  &.give-up {
    margin-left: 20px;
    // background: var(--al-component-bg-03-color);
    // border:1px solid var(--al-border-color);
  }
   &:hover{
    background: var(--al-main-color);
  }
}
</style>
<style lang="scss">
.select-pig-modal {
  width: 800px;
  height: 473px;
  background: var(--al-dialog-bg-color);
  border: 1px solid var(--al-border-color);
  border-radius: 5px;
  .el-carousel__arrow--left {
    left: -22px;
    background: red;
  }
  .el-carousel__arrow--left {
    right: -22px;
  }
  .el-carousel__container {
    height: 473px;
  }
  .el-dialog__body {
    padding: 0 62px;
  }
  .pid-detail-container {
    padding-top: 71px;
  }
  .carousel-item {
  }
  .el-dialog__header {
    display: none;
  }
  .el-carousel__item {
    padding: 0 20px;
  }
}
</style>
<style lang="scss" scoped></style>
