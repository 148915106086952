<template>
  <div class="app-wrap">
    <Home v-if="tabType" />
    <History v-if="!tabType" />
  </div>
</template>

<script>
import Home from './home/index'
import History from './history/index.vue'
export default {
  components: {
    Home,
    History,
  },
  data() {
    return {}
  },
  computed: {
    tabType() {
      return this.$store.state.tabType
    },
  },
  watch: {
    router(val, old) {
      console.log(val)
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.app-wrap {
}
</style>
