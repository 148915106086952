<template>
  <transition name="el-fade-in-linear">
    <div class="leave-wrap" v-if="openleave" @click="onEndMark">
      <i class="el-icon-circle-close close" @click="close"></i>
      <div class="main">
        <div class="leave-box1" @click="end" v-if="openEnd">
          <img :src="endIcon" alt="" />
          <span> 结束协作 </span>
        </div>
        <div class="leave-box2" @click="levae">
          <img :src="leaveIcon" alt="" />
          <span> 离开协作 </span>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import endIcon from '../assets/img/stop.svg'
import leaveIcon from '../assets/img/exit.svg'
import { destroyRoom } from '../api/index'
import Emitter from '../thirdParty/Emitter'
export default {
  props: [
    'leaveVisible',
    'leaveCallBack',
    'endVisible',
    'unPublishTrack',
    'emptyStore',
    'onRongLevae',
    'unSubscribeTracks',
  ],
  data() {
    return {
      openleave: false,
      openEnd: false,
      endIcon: endIcon,
      leaveIcon: leaveIcon,
    }
  },
  watch: {
    leaveVisible(val, old) {
      this.openleave = val
    },
    endVisible(val, old) {
      this.openEnd = val
    },
  },
  computed: {
    rtcClient() {
      return this.$store.state.rtcClient.getRtcClient()
    },
    rtcRoom() {
      return this.$store.state.rtcClient.getRoom()
    },
    rcRtcCode() {
      return this.$store.state.rtcClient.getRCRTCCode()
    },
    imClient() {
      return this.$store.state.rongClient.getImLib()
    },
    roomId() {
      return this.$store.state.roomInfo.roomId
    },
  },

  mounted() {
    Emitter.on('onLeave', this.levae)
  },
  methods: {
    onEndMark(evt) {
      this.leaveCallBack(false, false)
    },
    close() {
      this.leaveCallBack(false, false)
    },
    /**
     * 销毁房间
     */
    end() {
      let data = {
        token: this.$store.state.user.userId,
        roomId: this.roomId,
      }
      destroyRoom(data).then(async (res) => {
        if (res.success) {
          // console.error('销毁房间')
          // await this.unPublishTrack()
          // this.unSubscribeTracks()
          // this.onRongLevae()
          // this.emptyStore()
        }
      })
    },
    /**
     * 离开房间
     * @param {*} type
     */
    async levae() {
      this.$router.push('/index')
      await this.unPublishTrack()
      this.unSubscribeTracks()
      this.onRongLevae()
      this.emptyStore()
    },
  },
  beforeDestroy() {
    Emitter.removeListener('onLeave', this.levae)
  },
}
</script>
<style lang="scss" scoped>
.leave-wrap {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 10001;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    user-select: none;
  }
  .main {
    width: 400px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    .leave-box1,
    .leave-box2 {
      cursor: pointer;
      user-select: none;
      width: 200px;
      height: 109px;
      background: var(--al-dialog-bg-color);
      border: 1px solid var(--al-border-color);

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 28px;
        height: 28px;
        display: inline-block;
        margin-bottom: 12px;
      }
      span {
        display: block;
        text-align: center;
        font-size: 16px;
        font-size: 16px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: var(--al-text-color1);
      }
    }
    .leave-box1 {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    .leave-box2 {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
</style>
