<template>
  <div class="player" ref="playerContainer">
    <span class="video-name">{{ this.name }}</span>
    <video
      :id="`video_${stream._streamId}`"
      :key="stream._streamId"
      ref="video"
      :class="leng == 1 ? 'video1' : 'video2'"
      :style="videoStyle"
      src=""
    ></video>
  </div>
</template>

<script>
import Emitter from '../../thirdParty/Emitter'
export default {
  name: 'playVideo',
  props: ['str1', 'mediaStream', 'leng'],

  data() {
    return {
      stream: this.mediaStream,
      videoStyle: {},
      lastWidth: 300,
      name: '',
    }
  },
  computed: {
    userList() {
      let list = this.$store.state.memberList
      return list
    },
  },
  watch: {
    mediaStream(val) {
      val.play(this.$refs.video)
      this.initName()
      this.calcVideoStyle()
    },
    leng() {
      this.calcVideoStyle()
    },
    layout() {
      this.calcVideoStyle()
    },
  },
  create() {},
  mounted() {
    this.stream.play(this.$refs.video)
    this.calcVideoStyle()
  },
  updated() {
    this.initName()
  },
  methods: {
    initName() {
      this.userList.forEach((item) => {
        if (this.mediaStream._userId == item.id) {
          console.log(item)
          this.name = item.name
        }
      })
    },
    calc() {
      const videoWidth = this.$refs.video.clientWidth
      const videoHeight = this.$refs.video.clientHeight
      const containerWidth = this.$refs.playerContainer.clientWidth
      const containerHeight = this.$refs.playerContainer.clientHeight

      if (videoWidth / videoHeight > containerWidth / containerHeight) {
        this.videoStyle = {
          width: `${containerWidth}px`,
        }
      } else {
        this.videoStyle = {
          height: `${containerHeight}px`,
        }
      }
      Emitter.emit(
        'resizeBackground',
        '计算完成video尺寸' + JSON.stringify(this.videoStyle)
      )
    },
    calcVideoStyle() {
      const videoWidth = this.$refs.video.clientWidth
      if (videoWidth === 300) {
        setTimeout(this.calcVideoStyle, 100)
      } else {
        this.calc()
        // 再增加一次计算绘制
        setTimeout(this.calc, 500)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.player {
  background-color: black;
  width: 100%;
  height: 100%;
  color: var(--al-text-color1);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border: 1px solid #fff;
  box-sizing: border-box;
  video {
    position: relative;
  }
  .video-name {
    // height: 20px;
    background: var(--al-component-bg-03-color);
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  .video1 {
    // width: 100%;
    // height: 100%;
    display: inline-block;
  }

  .video2 {
    // width: 100%;
    // height: 100%;
    display: inline-block;
  }
}
</style>
