<template>
  <div class="wrap">
    <div class="tab">
      <span
        v-for="(item, index) in tabArr"
        :key="index"
        :class="tabType == item.type ? 'active' : ''"
        @click="tabClick(item.type)"
        >{{ item.name }}</span
      >
    </div>
    <nav>
      <el-input
        v-model="pigNumber"
        placeholder="请输入猪只耳号"
        size="mini"
        @keyup.enter.native="handleSearch"
        @blur="handleSearch"
        class="pig-id-input"
      ></el-input>
    </nav>
    <ul class="pig-list">
      <li class="pig-item" v-for="(item, index) in list" :key="item.id">
        <span class="pig-icon"></span>
        <span class="pig-id">{{ item.id }}</span>
        <span class="pig-user-name">{{ item.username }}</span>
        <div class="pig-btn" @click="pigDetail(index)">详情</div>
      </li>
    </ul>

    <pig-modals
      :visible="pigDetailVisible"
      :pigs="list"
      :type="tabType"
      :index="pigIndex"
      @close="pigDetailVisible = false"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import pig from '../../assets/img/pig.svg'
import { getPigList } from '../../api'
import PigModals from './PigModals.vue'
import emitter from '../../thirdParty/Emitter'

export default {
  components: {
    PigModals,
  },
  computed: {
    ...mapState(['roomInfo']),
  },
  data() {
    return {
      pigDetailVisible: false, //详情控制
      pigNumber: '',
      pigSvg: pig,
      tabType: 1,
      pigInfo: {},
      pigIndex: 0,
      tabArr: [
        { name: '选中', type: 1 },
        { name: '弃选', type: 2 },
      ],
      list: [],
    }
  },
  watch: {
    list(val) {
      if (val.length < 1) {
        this.pigDetailVisible = false
      }
    },
  },
  mounted() {
    this.getList()
    emitter.on('refreshPigList', this.getList)
  },
  methods: {
    async getList() {
      const query = {
        roomId: this.roomInfo.roomId,
        status: this.tabType === 1 ? true : false,
      }

      if (this.pigNumber) {
        query.id = this.pigNumber
      }
      const res = await getPigList(query)
      this.list = res.data
    },
    handleSearch() {
      this.getList()
    },
    /**
     * tab切换猪只列表
     * @param {*} type
     */
    tabClick(type) {
      this.tabType = type
      this.getList()
    },
    /**
     * 猪只详情
     * @param {*} data
     */
    pigDetail(index) {
      this.pigIndex = index
      this.getList()
      this.pigDetailVisible = true
    },
    /**
     * 猪只详情回调
     * @param {*} visible
     */
    pigCallBack(visible) {
      this.pigDetailVisible = visible
    },
  },
  beforeDestroy() {
    emitter.removeListener('selectPig', this.getList)
  },
}
</script>
<style lang="scss" scoped>
.close-icon {
  font-size: 22px;
  color: var(--al-text-color2);
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}
.wrap {
  height: 100%;
}
.tab {
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    cursor: pointer;
    user-select: none;
    margin: 0 28px;
    color: var(--al-text-03-color1);
    font-size: 12px;
    line-height: 18px;
    &.active {
      border-bottom: 1px solid var(--al-main-color);
      color: var(--al-text-color1);
    }
  }
}
nav {
  padding: 12px 20px;
}

.pig-list {
  overflow: auto;
  height: calc(100% - 120px);
  padding: 0 20px;
}
.pig-list::-webkit-scrollbar {
  display: none;
}
.pig-item {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: var(--al-text-color1);
  border-bottom: 1px solid var(--al-border-color);
  min-width: 0;
  .pig-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('../..//assets/img/pig-white-icon.png') no-repeat center
      center/contain;
    user-select: none;
  }
  .pig-id {
    display: inline-block;
    width: 90px;

    flex-grow: 2;
    margin-left: 7px;
    white-space: nowrop;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .pig-user-name {
    display: inline-block;
    width: 40px;
    font-size: 12px;
    transform: scale(0.8);
  }
  .pig-btn {
    height: 20px;
    width: 53px;
    background: var(--al-main-03-color);
    border-radius: 10px;
    margin-left: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.select-btns {
  position: absolute;
  bottom: 50px;
  left: 156px;
  display: flex;
  width: 280px;
  justify-content: center;
}
.select-btn {
  flex-shrink: 0;
  width: 120px;
  height: 40px;
  background: var(--al-main-color);
  border-radius: 3px;
  color: var(--al-text-color2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  &.give-up {
    margin-left: 20px;
    background: var(--al-component-bg-03-color);
    border: 1px solid var(--al-border-color);
  }
}
</style>

<style lang="scss">
.pig-detail-modal {
  width: 800px;
  height: 473px;
  background: var(--al-dialog-bg-color);
  border: 1px solid var(--al-border-color);
  border-radius: 5px;
  .el-dialog__body {
    padding: 71px 62px 0px 62px;
  }
  .el-dialog__header {
    display: none;
  }
}
.pig-id-input {
  height: 30px;
  border: 1px solid var(--al-border-color);
  border-radius: 5px;
  .el-input__inner {
    height: 100%;
    background: transparent;
    border: none;
    color: var(--al-text-color1);
  }
  .el-input__inner::-webkit-input-placeholder {
    color: var(--al-text-color1);
  }
  .el-input__icon {
    line-height: 100%;
    color: var(--al-text-color1);
  }
}
</style>
